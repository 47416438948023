<template>
  <div class="home">
    <v-row>
      <!-- INICIO TOOLBAR -->
      <v-col class="contenedor pa-0 ma-0" cols="12">
        <v-toolbar dark color="dark" height="40">
          <!-- <v-btn color="blue" icon  small class="ml-3"> <v-icon> mdi-refresh</v-icon></v-btn> -->
          <!-- DIALOG -->
          <v-dialog v-model="modal_agregar_consulta" max-width="1200px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" color="grey darken-1" small>
                <v-icon> mdi-plus</v-icon></v-btn
              >
            </template>

            <v-card>
              <ModalAgregarConsulta />

              <v-row class="mt-2">
                <v-spacer></v-spacer>
                <v-col cols="2">
                  <v-btn color="red" dark small @click="cerrarModal()" class="mb-2">
                    Cerrar
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-dialog>
          <!--  FIN DIALOG -->

          <v-spacer></v-spacer>
          <v-toolbar-title class="titulo_toolbar mr-15">DESPACHO</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-col>
      <!-- FIN TOOLBAR -->

      <!-- COLUMNA 1 -->
      <v-col class="contenedor" cols="12" sm="6" md="4" lg="4">
        <!-- RADICACIÓN, TRIAJE -->
        <v-row>
          <v-col cols="3" lg="2" md="2" sm="2">
            <div name="radicacion" class="content">
              <label for="radicacion" class="etiqueta">Radicación</label>
            </div>
          </v-col>

          <v-col cols="9" lg="6" md="5" sm="6">
            <div name="radicacion" class="content">
              <input
                type="text"
                id="radicacion"
                class="text_field_disabled"
                readonly
                v-model="radicacion"
              />
            </div>
          </v-col>

          <v-col cols="3" lg="1" md="2" sm="2">
            <div name="triaje">
              <label for="triaje" class="etiqueta ml-1">Triaje</label>
            </div>
          </v-col>

          <v-col cols="9" lg="3" md="" sm="2">
            <div class="custom-select">
              <select
                v-model="triaje"
                :class="{ text_field_disabled }"
                :disabled="triaje"
                v-on:blur="eventBlurTriaje"
              >
                <option value="A">Amarillo</option>
                <option value="V">Verde</option>
                <option value="R">Rojo</option>
              </select>
              <span class="custom-arrow">
                <i class="fal fa-chevron-down arrow-down"></i>
              </span>
            </div>
          </v-col>
        </v-row>

        <!-- #RADICACIÓN, F ING -->
        <v-row class="ma-0 pa-0">
          <v-col cols="3" lg="2" md="2" sm="2">
            <div name="#radicacion">
              <label for="#radicacion" class="etiqueta">#Radicación</label>
            </div>
          </v-col>

          <v-col cols="9" lg="5" md="5" sm="4">
            <div name="#radicacion">
              <input
                type="text"
                readonly
                v-model="n_radicacion"
                class="text_field_disabled"
                id="#radicacion"
              />
            </div>
          </v-col>

          <!-- LAS FECHAS DEBEN IR EN ESTE FORMATO "YYYY-MM-DD" EJE: 2017-07-04   -->
          <v-col cols="3" lg="1" md="2" sm="2">
            <div name="f-ingreso">
              <label for="f-ingreso" class="etiqueta ml-1">F Ing</label>
            </div>
          </v-col>

          <v-col cols="9" lg="4" md="3" sm="4">
            <div name="f-ingreso">
              <input
                type="date"
                class="text_field_disabled fechas"
                v-model="f_ing"
                id="f-ingreso"
              />
            </div>
          </v-col>
        </v-row>

        <!-- CONSECUTIVO, FROP -->
        <v-row class="ma-0 pa-0">
          <v-col cols="3" lg="2" md="2" sm="2">
            <v-dialog v-model="modal_consecutivo" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <a v-bind="attrs" v-on="on" for="consecutivo" class="etiqueta"
                  >Consecutivo</a
                >
              </template>
              <v-card>
                <v-toolbar dark color="grey darken-3" height="40">
                  <v-spacer></v-spacer>
                  <v-toolbar-title>Informe Consecutivo</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>

                <v-card-text class="mt-4">
                  <v-row>
                    <v-spacer></v-spacer>
                    <table class="fila">
                      <tr>
                        <th></th>
                        <th>Descripción</th>
                      </tr>
                      <tr>
                        <td>Operador Despacho:</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Hora Despacho:</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Operador Creador:</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Fecha Creación</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Usuario Prioridad Por Sintoma</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Hora Prioridad Por Sintoma</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Usuario Actualiza Dirección</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Usuario Confirma Dirección:</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Hora Confirmación de Dirección:</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Creación de Dirección:</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Edición de Dirección:</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Revaloración:</td>
                        <td></td>
                      </tr>
                    </table>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="red" dark small @click="cerrarModal()"> Cerrar </v-btn>
                  <v-btn small color="primary" @click="aceptarModal()"> Aceptar </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>

          <v-col cols="9" lg="5" md="5" sm="4">
            <div name="consecutivo">
              <input
                type="text"
                readonly
                v-model="consecutivo"
                class="text_field_disabled"
                id="consecutivo"
              />
            </div>
          </v-col>

          <!-- LAS FECHAS DEBEN IR EN ESTE FORMATO "YYYY-MM-DD" EJE: 2017-07-04   -->
          <v-col cols="3" lg="1" md="2" sm="2">
            <div name="frop">
              <label for="frop" class="etiqueta ml-1">FROp</label>
            </div>
          </v-col>

          <v-col cols="9" lg="4" md="3" sm="4">
            <div name="frop">
              <input
                type="date"
                v-model="f_rop"
                class="text_field_disabled fechas"
                id="frop"
              />
            </div>
          </v-col>
        </v-row>

        <!-- TITULAR -->
        <v-row class="ma-0 pa-0">
          <v-col cols="3" lg="2" md="2" sm="2">
            <div name="titular">
              <label for="titular" class="etiqueta">Titular</label>
            </div>
          </v-col>

          <v-col cols="9" lg="10" md="10" sm="10">
            <div name="titular">
              <input
                type="text"
                readonly
                v-model="titular"
                class="text_field_disabled"
                id="titular"
              />
            </div>
          </v-col>
        </v-row>

        <!-- PACIENTE -->
        <v-row class="ma-0 pa-0">
          <v-col cols="3" lg="2" md="2" sm="2">
            <div name="paciente">
              <label for="paciente" class="etiqueta">Paciente</label>
            </div>
          </v-col>

          <v-col cols="9" lg="10" md="10" sm="10">
            <div name="paciente">
              <input
                type="text"
                readonly
                v-model="paciente"
                class="text_field_disabled"
                id="paciente"
              />
            </div>
          </v-col>
        </v-row>

        <!-- DIRECCION CON DIALOG -->
        <v-row class="ma-0 pa-0">
          <v-col cols="3" lg="2" md="2" sm="2">
            <div name="direccion">
              <v-dialog v-model="modal_direccion" max-width="800px">
                <template v-slot:activator="{ on, attrs }">
                  <a v-bind="attrs" v-on="on" for="direccion" class="etiqueta"
                    >Direccion</a
                  >
                </template>
                <v-card>
                  <ModalDireccion />
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" dark small @click="cerrarModal()"> Cerrar </v-btn>
                    <v-btn small color="primary" @click="aceptarModal()"> Aceptar </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
          <v-col cols="9" lg="10" md="10" sm="10">
            <div name="direccion">
              <input
                type="text"
                readonly
                v-model="direccion"
                class="text_field_disabled"
                id="direccion"
              />
            </div>
          </v-col>
        </v-row>

        <!-- TELEFONO, CELULAR-->
        <v-row class="ma-0 pa-0">
          <v-col cols="3" lg="2" md="2" sm="2" xs="">
            <div name="telefono">
              <label for="telefono" class="etiqueta">Telefono</label>
            </div>
          </v-col>

          <v-col cols="9" lg="4" md="4" sm="4">
            <div name="telefono">
              <input
                type="text"
                v-model="telefono"
                class="text-field tf-telefono"
                id="telefono"
                v-on:blur="eventoBlurTelefono"
              />
            </div>
          </v-col>

          <v-col cols="3" lg="2" md="2" sm="2">
            <div name="celular">
              <label for="celular" class="etiqueta ml-2">Celular</label>
            </div>
          </v-col>

          <v-col cols="9" lg="4" md="4" sm="4">
            <div name="celular">
              <input
                type="text"
                v-model="celular"
                v-on:blur="eventoBlurCelular"
                class="text-field"
                id="celular"
              />
            </div>
          </v-col>
        </v-row>

        <!-- EDAD,SEXO, DOC ID -->
        <v-row class="ma-0 pa-0">
          <!--  EDAD -->
          <v-col cols="2" lg="1" md="1" sm="">
            <div name="edad">
              <label for="edad" class="etiqueta">Edad</label>
            </div>
          </v-col>

          <v-col cols="1" lg="2" md="1" sm="">
            <div name="edad">
              <input
                type="text"
                readonly
                v-model="edad"
                class="text_field_disabled"
                id="edad"
              />
            </div>
          </v-col>
          <!-- FIN EDAD -->

          <!-- SEXO -->
          <v-col cols="2" lg="1" md="1" sm="">
            <div name="sexo">
              <label for="sexo" class="etiqueta ml-1">Sexo</label>
            </div>
          </v-col>

          <v-col cols="1" lg="2" md="1" sm="">
            <div name="sexo">
              <input
                type="text"
                readonly
                v-model="sexo"
                class="text_field_disabled ml-1"
                id="sexo"
              />
            </div>
          </v-col>
          <!-- SEXO -->

          <!-- DOC ID -->
          <v-col cols="2" lg="2" md="2" sm="">
            <div name="docid">
              <v-dialog v-model="modal_doc_id" max-width="1200px">
                <template v-slot:activator="{ on, attrs }">
                  <a v-bind="attrs" v-on="on" for="direccion" class="etiqueta ml-2"
                    >Doc Id</a
                  >
                </template>

                <v-card>
                  <v-toolbar dark color="grey darken-3" height="40">
                    <v-spacer></v-spacer>
                    <v-toolbar-title>Bitacora</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-card class="mt-2">
                    <v-card-title> </v-card-title>
                    <v-col sm="3" class="ml-4">
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Buscar"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-data-table
                      :headers="headers_datatable_doc_id"
                      :items="data_datatable_doc_id"
                      :search="search"
                    ></v-data-table>
                  </v-card>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" dark small @click="cerrarModal()"> Cerrar </v-btn>
                    <v-btn small color="primary" @click="aceptarModal()"> Aceptar </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>

          <v-col cols="4" lg="4" md="6" sm="">
            <div name="docid">
              <input
                type="text"
                readonly
                v-model="doc_id"
                class="text_field_disabled"
                id="docid"
              />
            </div>
          </v-col>
          <!-- FIN DOC ID -->
        </v-row>
      </v-col>

      <!-- COLUMNA 2 -->
      <v-col class="contenedor" cols="12" sm="6" md="4" lg="4">
        <!-- TELEFONO DE DONDE SE LLAMA -->
        <v-row class="ma-0 pa-0">
          <v-col cols="5" lg="4" md="5" sm="6">
            <div name="tel-usuario">
              <label for="tel-usuario" class="etiqueta">Telefono de donde se llama</label>
            </div>
          </v-col>

          <v-col cols="6" lg="7" md="6" sm="5">
            <div name="tel-usuario">
              <input
                type="text"
                v-model="tel_de_se_donde_llama"
                class="text-field"
                id="tel-usuario"
                v-on:blur="eventoBlurTelefonoDeDondeLlama"
              />
            </div>
          </v-col>

          <!-- BOTON DE DONDE SE LLAMA -->
          <v-col cols="1" lg="1" md="1" sm="1">
            <div>
              <button class="boton"><i class="fas fa-save"></i></button>
            </div>
          </v-col>
        </v-row>

        <!-- PERSONA QUE LLAMA -->
        <v-row class="ma-0 pa-0">
          <v-col cols="5" lg="4" md="5" sm="5">
            <div name="pllama">
              <label for="pllama" class="etiqueta">Persona que llama</label>
            </div>
          </v-col>

          <v-col cols="7" lg="8" md="7" sm="7">
            <div name="pllama">
              <input
                type="text"
                v-model="persona_que_llama"
                class="text-field"
                id="pllama"
                v-on:blur="eventoBlurPersonaQueLlama"
              />
            </div>
          </v-col>
        </v-row>

        <!-- DIR EMERGENCIA CON DIALOG -->
        <v-row class="ma-0 pa-0">
          <v-col cols="3" lg="4" md="5" sm="4">
            <div name="d-emergencia">
              <v-dialog v-model="modal_dir_emergencia" max-width="800px">
                <template v-slot:activator="{ on, attrs }">
                  <a v-bind="attrs" v-on="on" for="direccion" class="etiqueta"
                    >Dir Emergencia</a
                  >
                </template>

                <v-card>
                  <ModalDireccion />

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" dark small @click="cerrarModal()"> Cerrar </v-btn>
                    <v-btn small color="primary" @click="aceptarModal()"> Aceptar </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>

          <v-col cols="8" lg="7" md="6" sm="7">
            <div name="d-emergencia">
              <input
                type="text"
                v-model="dir_emergencia"
                class="text-field"
                id="d-emergencia"
              />
            </div>
          </v-col>

          <v-col cols="1" lg="1" md="1" sm="1">
            <div>
              <button @click="eventoBlurDireccionEmergencia" class="boton">
                <i class="fas fa-save"></i>
              </button>
            </div>
          </v-col>
        </v-row>

        <!-- CEL NOTIFICACIONES, EPS -->
        <v-row class="ma-0 pa-0">
          <v-col cols="5" lg="3" md="4" sm="3">
            <div name="c-notificaciones">
              <v-dialog v-model="modal_cel_notificaciones" max-width="800px">
                <template v-slot:activator="{ on, attrs }">
                  <a v-bind="attrs" v-on="on" for="direccion" class="etiqueta"
                    >Cel Notificaciones
                  </a>
                </template>

                <v-card>
                  <ModalCelNotificaciones />

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" dark small @click="cerrarModal()"> Cerrar </v-btn>
                    <v-btn small color="primary" @click="aceptarModal()"> Aceptar </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>

          <v-col cols="6" lg="4" md="3" sm="3">
            <div name="c-notificaciones">
              <input
                type="text"
                v-model="cel_notificaciones"
                class="text-field"
                id="c-notificaciones"
              />
            </div>
          </v-col>

          <v-col cols="1" lg="1" md="1" sm="2">
            <div>
              <button @click="eventoBlurCelNotificaciones" class="boton-pequeño ml-1">
                <i class="fas fa-save"></i>
              </button>
            </div>
          </v-col>

          <v-col cols="3" lg="1" md="1" sm="1">
            <div name="eps">
              <label for="eps" class="etiqueta ml-1">EPS</label>
            </div>
          </v-col>

          <v-col cols="9" lg="3" md="3" sm="3">
            <div name="eps">
              <div class="custom-select">
                <select
                  name="eps"
                  v-model="eps_seleccionada"
                  v-on:change="eventoBlurEPS"
                  id="eps"
                >
                  <option v-for="item in eps" :key="item.item" :value="item.value">
                    {{ item.item }}
                  </option>
                </select>
                <span class="custom-arrow">
                  <i class="fal fa-chevron-down arrow-down"></i>
                </span>
              </div>
            </div>
          </v-col>
        </v-row>

        <!-- SIGNOS Y SINTOMAS -->
        <v-row class="ma-0 pa-0">
          <v-col cols="4" lg="3" md="4" sm="4">
            <div name="signos-sintomas">
              <v-dialog v-model="modal_signos_sintomas" max-width="240px">
                <template v-slot:activator="{ on, attrs }">
                  <a v-bind="attrs" v-on="on" for="direccion" class="etiqueta"
                    >Signos y sintomas</a
                  >
                </template>

                <v-card>
                  <ModalSignosSintomas />

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" dark small @click="cerrarModal()"> Cerrar </v-btn>
                    <v-btn small color="primary" @click="aceptarModal()"> Aceptar </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>

          <v-col cols="8" lg="9" md="8" sm="8">
            <div name="signos-sintomas">
              <input
                type="text"
                v-model="signos_sintomas"
                class="text-field"
                id="signos-sintomas"
                v-on:blur="eventoBlurSignosYSintomas"
              />
            </div>
          </v-col>
        </v-row>

        <!-- EVALUACIÓN -->
        <v-row class="ma-0 pa-0">
          <v-col cols="3" lg="3" md="2" sm="2">
            <v-dialog v-model="modal_evaluacion" max-width="800px">
              <template v-slot:activator="{ on, attrs }">
                <a v-bind="attrs" v-on="on" for="direccion" class="etiqueta"
                  >Evaluación
                </a>
              </template>

              <v-card>
                <ModalCelNotificaciones />

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="red" dark small @click="cerrarModal()"> Cerrar </v-btn>
                  <v-btn small color="primary" @click="aceptarModal()"> Aceptar </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col cols="3" lg="2" md="4" sm="3">
            <div name="evaluacion">
              <input
                type="text"
                v-model="evaluacion"
                class="text-field"
                id="evaluacion"
                v-on:blur="eventoBlurEvaluacion"
              />
            </div>
          </v-col>

          <v-col cols="6" lg="7" md="6" sm="">
            <div class="custom-select ml-1">
              <!--<select name="" id="">
                <option value="">Politraumatismo</option>
              </select>-->
              <select
                name="sintomas"
                v-on:change="eventoBlurEvaluacion"
                v-model="evaluacion"
                id="sintomas"
              >
                <option v-for="item in sintomas" :key="item.item" :value="item.value">
                  {{ item.item }}
                </option>
              </select>
              <span class="custom-arrow">
                <i class="fal fa-chevron-down arrow-down"></i>
              </span>
            </div>
          </v-col>
        </v-row>

        <!-- T CONSULTAS, MESES, RLLAMADA, RRING -->
        <v-row class="ma-0 pa-0">
          <v-col cols="3" lg="2" md="3" sm="3">
            <div name="t-consultas">
              <v-dialog v-model="modal_t_consultas" max-width="1000px">
                <template v-slot:activator="{ on, attrs }">
                  <a
                    @click="totalServiciosDespacho"
                    v-bind="attrs"
                    v-on="on"
                    for="direccion"
                    class="etiqueta"
                    >T. Consultas</a
                  >
                </template>

                <v-card>
                  <v-toolbar dark color="grey darken-3" height="40">
                    <v-spacer></v-spacer>
                    <v-toolbar-title>Tabla</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-card class="mt-2">
                    <v-card-title> </v-card-title>
                    <v-col sm="3" class="ml-4">
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Buscar"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-data-table
                      :headers="headers_t_consultas"
                      :items="data_t_consultas"
                      :search="search"
                    ></v-data-table>
                  </v-card>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" dark small @click="cerrarModal()"> Cerrar </v-btn>
                    <v-btn small color="primary" @click="aceptarModal()"> Aceptar </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>

          <v-col cols="1" lg="1" md="1" sm="1">
            <div name="t-consultas">
              <input
                type="text"
                v-model="t_consultas"
                class="text_field_disabled"
                id="t-consultas"
              />
            </div>
          </v-col>

          <v-col cols="1" lg="1" md="1" sm="1">
            <div name="t-consultas ">
              <label for="meses" class="etiqueta ml-1">Meses</label>
            </div>
          </v-col>

          <v-col cols="1" lg="1" md="1" sm="">
            <div name="t-consultas">
              <input
                type="text"
                v-model="meses"
                class="text_field_disabled ml-2"
                id="meses"
              />
            </div>
          </v-col>

          <v-col cols="2" lg="2" md="2" sm="">
            <div name="t-consultas">
              <!-- MODAL REGISTRO LLAMADA -->
              <v-dialog v-model="modal_registro_llamada" max-width="800px">
                <template v-slot:activator="{ on, attrs }">
                  <a v-bind="attrs" v-on="on" for="direccion" class="etiqueta ml-3"
                    >Rllamada</a
                  >
                </template>

                <v-card>
                  <ModalRegistroLlamada />

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" dark small @click="cerrarModal()"> Cerrar </v-btn>
                    <v-btn small color="primary" @click="aceptarModal()"> Aceptar </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>

          <v-col cols="1" lg="2" md="1" sm="">
            <div name="t-consultas">
              <input
                type="text"
                v-model="r_llamada"
                class="text_field_disabled ml-1"
                id="rllamada"
              />
            </div>
          </v-col>

          <v-col cols="1" lg="1" md="2" sm="">
            <div name="t-consultas">
              <label for="nring" class="etiqueta ml-2">NRing</label>
            </div>
          </v-col>

          <v-col cols="1" lg="2" md="1" sm="">
            <div name="t-consultas">
              <input
                type="text"
                v-model="n_ring"
                class="text_field_disabled ml-1"
                id="nring"
              />
            </div>
          </v-col>
        </v-row>

        <!-- BOTONES (BADGES) Y REVALORACIÓN -->
        <v-row class="ma-0 pa-0">
          <!-- REVALORACIÓN -->
          <v-col cols="3" lg="2" md="3" sm="3">
            <div name="revaloracion">
              <label for="revaloracion" class="etiqueta">Revaloracion</label>
            </div>
          </v-col>

          <v-col cols="9" lg="2" md="3" sm="3">
            <div name="revaloracion">
              <div class="custom-select">
                <select
                  name="revaloracion"
                  v-on:change="eventoBlurRevaloracion"
                  v-model="revaloracion"
                  id="revaloracion"
                >
                  <option value="A">Amarillo</option>
                  <option value="V">Verde</option>
                  <option value="R">Rojo</option>
                </select>
                <span class="custom-arrow">
                  <i class="fal fa-chevron-down arrow-down"></i>
                </span>
              </div>
            </div>
          </v-col>

          <!-- BADGES -->
          <v-col cols="12" lg="7" md="6" sm="">
            <div name="banderas ">
              <v-dialog v-model="modal_pqrs" max-width="800px">
                <template v-slot:activator="{ on, attrs }">
                  <button v-bind="attrs" v-on="on" class="bandera">PQRS</button>
                </template>

                <v-card>
                  <ModalPQRS />

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" dark small @click="cerrarModal()"> Cerrar </v-btn>
                    <v-btn small color="primary" @click="aceptarModal()"> Aceptar </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="modal_sms" max-width="1200px">
                <template v-slot:activator="{ on, attrs }">
                  <button v-bind="attrs" v-on="on" class="bandera bandera2">SMS</button>
                </template>

                <v-card>
                  <v-toolbar dark color="grey darken-3" height="40">
                    <v-spacer></v-spacer>
                    <v-toolbar-title>Informe SMS</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-card class="mt-2">
                    <v-card-title> </v-card-title>
                    <v-col sm="3" class="ml-4">
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Buscar"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-data-table
                      :headers="headers_datatable_sms"
                      :items="data_datatable_sms"
                      :search="search"
                    ></v-data-table>
                  </v-card>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" dark small @click="cerrarModal()"> Cerrar </v-btn>
                    <v-btn small color="primary" @click="aceptarModal()"> Aceptar </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <button class="bandera bandera2">G.Despacho</button>

              <a
                href="http://redmedica:83/mapas_dev/"
                target="_blank"
                class="bandera bandera2"
                >Mapas</a
              >
            </div>
          </v-col>
        </v-row>
      </v-col>

      <!-- COLUMNA 3 -->
      <v-col class="contenedor" cols="12" sm="12" md="4" lg="4">
        <v-row class="ma-0 pa-0">
          <!-- T SERV -->
          <v-col cols="2" lg="2" md="2" sm="2">
            <div name="tserv">
              <label for="tserv" class="etiqueta">T. Serv</label>
            </div>
          </v-col>

          <v-col cols="4" lg="4" md="3" sm="4">
            <div name="tserv">
              <div class="custom-select">
                <select
                  name="tserv"
                  v-on:change="eventoBlurTserv"
                  v-model="t_serv_seleccionado"
                  id="tserv"
                >
                  <option v-for="item of t_serv" :key="item.item" :value="item.value">
                    {{ item.item }}
                  </option>
                </select>
                <span class="custom-arrow">
                  <i class="fal fa-chevron-down arrow-down"></i>
                </span>
              </div>
            </div>
          </v-col>

          <!-- NOVEDAD -->
          <v-col cols="2" lg="2" md="2" sm="2">
            <div name="tserv">
              <label for="novedad" class="etiqueta">Novedad</label>
            </div>
          </v-col>

          <v-col cols="4" lg="4" md="3" sm="4">
            <div class="custom-select">
              <select
                name="novedad"
                v-on:change="eventoBlurNovedad"
                v-model="novedad_seleccionada"
                id="novedad"
              >
                <option v-for="item of novedad" :key="item.item" :value="item.value">
                  {{ item.item }}
                </option>
              </select>
              <span class="custom-arrow">
                <i class="fal fa-chevron-down arrow-down"></i>
              </span>
            </div>
          </v-col>
        </v-row>

        <!-- M CAN -->
        <v-row class="ma-0 pa-0">
          <v-col cols="2" lg="2" md="2" sm="2">
            <div name="mcan">
              <label for="mcan" class="etiqueta">M Can</label>
            </div>
          </v-col>

          <v-col cols="7" lg="4" md="3" sm="4">
            <div class="custom-select">
              <select
                name="mcan"
                id="mcan"
                v-on:change="eventoBlurMcan"
                v-model="m_can_seleccionada"
              >
                <option v-for="item of m_can" :key="item.item" :value="item.value">
                  {{ item.item }}
                </option>
              </select>
              <span class="custom-arrow">
                <i class="fal fa-chevron-down arrow-down"></i>
              </span>
            </div>
          </v-col>

          <!-- F PAGO -->
          <v-col cols="3" lg="3" md="2" sm="6">
            <div>
              <v-dialog v-model="modal_forma_pago" max-width="240px">
                <template v-slot:activator="{ on, attrs }">
                  <button v-bind="attrs" v-on="on" class="bandera">F.pago</button>
                </template>

                <v-card>
                  <ModalFPago />

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" dark small @click="cerrarModal()"> Cerrar </v-btn>
                    <v-btn small color="primary" @click="aceptarModal()"> Aceptar </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <!-- PROGRESS BAR -->
        <v-row class="ma-0 pa-0">
          <!-- El progress bar tiene bindeado el atributo :value="ps" para evitar modificar la barra de progreso
                   desde el front. Si establecemos el enlace con v-model="ps", el progreso se podra modificar
                   por el usuario desde la vista.
                   -->
          <v-col cols="2" lg="2" md="2" sm="2">
            <div>
              <label for="ps" class="etiqueta">PS {{ progress_bar }}%</label>
            </div>
          </v-col>

          <v-col cols="10" lg="10" md="10" sm="10" class="mt-2">
            <div>
              <v-progress-linear
                height="13"
                color="light-blue darken-1"
                striped
                :value="progress_bar"
              >
                <strong class="porcentaje_progress_bar"
                  >{{ Math.ceil(progress_bar) }}%</strong
                >
              </v-progress-linear>
            </div>
          </v-col>
        </v-row>
        <!--FIN PROGRESS BAR -->

        <!-- TABS - ANTECEDENTES, N. CONSULTAS, N. OPERADOR -->
        <!-- Estas tabs tienen 3 tab item que contienen cada uno su respectivo text-area,
                 por lo tanto el enlace bidireccional a cada text-area se debe realizar y manejar de manera independiente. -->
        <v-row class="ma-0 pa-0">
          <v-col cols="12" sm="12" md="12">
            <!-- CABECERAS  -->
            <v-tabs v-model="tabs_antecedentes" height="22" fixed-tabs centered>
              <v-tab v-for="n in headers_tab" :key="n">
                {{ n }}
              </v-tab>
            </v-tabs>

            <!-- TABS -->
            <v-tabs-items v-model="tabs_antecedentes">
              <!-- TAB 1 -->
              <v-tab-item>
                <textarea
                  v-on:blur="eventoBlurAntecedentes"
                  class="text-area"
                  v-model="textarea_antecedentes"
                ></textarea>
                <v-divider></v-divider>
              </v-tab-item>

              <!-- TAB 2 -->
              <v-tab-item>
                <textarea
                  v-on:blur="eventoBlurNConsulta"
                  class="text-area"
                  v-model="textarea_n_consulta"
                ></textarea>
                <v-divider></v-divider>
              </v-tab-item>

              <!-- TAB 3 -->
              <v-tab-item>
                <textarea
                  v-on:blur="eventoBlurNOperador"
                  class="text-area"
                  v-model="textarea_n_operador"
                ></textarea>
                <v-divider></v-divider>
              </v-tab-item>
            </v-tabs-items>
            <!-- FIN TABS -->
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <template>
      <v-card>
        <v-toolbar dark color="grey darken-3" height="40">
          <v-btn color="blue" icon small class="ml-3" @click="buscar()">
            <v-icon> mdi-refresh</v-icon></v-btn
          >
          <v-spacer></v-spacer>
          <v-toolbar-title>Información de Despacho</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-title>
          <v-col sm="4" class="ml-4 mt-3 mb-2">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-card-title>
        <!-- <v-data-table
            :headers="headers_datatable_info_despacho"
            :items="data_datatable_info_despacho"
            :search="search"
            >
           
            </v-data-table> -->

        <template>
          <v-data-table
            dense
            :headers="headers_datatable_info_despacho"
            :items="data_datatable_info_despacho"
            sort-by="calories"
            class="elevation-1"
            @click:row="handleClick"
          >
            <!-- VALIDACIÓN EN COLUMNA CONSECUTIVO -->
            <!-- 
Se inyecta un slot que permite realizar la validación del consecutivo(reqcsc) y el consecutivo de 
traslado (REQCSCTrasl).Esta validación se realiza porque hay casos en los que por ejemplo un usuario 
solicita un servicio y llega un carro (particular) a antender al paciente, (en ese momento se genera
un consecutivo), luego se pide una ambulancia, entonces la ambulancia llega al lugar donde se encuentra 
el paciente y eso genera otro consecutivo. El consecutivo que se le da a la ambulancia viene del 
consecutivo que se le da al carro (Ambos consecutivos estan relacionados).

La validación evalua que el consecutivo de traslado (REQCSCTrasl) exista y que no sea nulo, si existe entonces
se mostraran ambos consecutivos (REQCSCTrasl y reqcsc) si no existe el consecutivo de traslado, entonces se mostrara 
solo el consecutivo (reqcsc)


v-slot:[`item.reqcsc`]="props" permite inyectar html en la columna de consecutivo (reqcsc). Los props
contienen la data. Para acceder ala dada correspondiente se debe llamar a los item 
de los props (props.item.reqcsc)
-->
            <template v-slot:[`item.reqcsc`]="props">
              <p v-if="props.item.REGCSCTrasl == 0 || props.item.REGCSCTrasl == null">
                {{ props.item.reqcsc }}
              </p>
              <p v-else>{{ props.item.reqcsc }} / {{ props.item.REGCSCTrasl }}</p>
            </template>

            <!-- FIN VALIDACIÓN CONSECUTIVOS -->

            <!--  VALIDACION DE HISTORIA CLINICA -->
            <!-- Si la historia clinica es igual a 1, entonces se evaluara que la hora disponible sea diferente de null
y que no este vacia. Si se cumple entonces se muestran los iconos azules, sino entonces se muestran grises.
Si no existe historia clinica entonces no se muestra nada -->

            <template v-slot:[`item.reqreg`]="props">
              <div v-if="props.item.reqreg == 1">
                <div v-if="props.item.reqhdi != '' && props.item.reqhdi != null">
                  <v-icon color="primary">mdi-check-all</v-icon>
                </div>
                <div v-else>
                  <v-icon color="red">mdi-check</v-icon>
                </div>
              </div>
              <div v-else></div>
            </template>

            <!-- FIN VALIDACION DE HISTORIA CLINICA -->
            <!-- EDIT DIALOG -->
            <template v-slot:item.Prg="props">
              <v-edit-dialog
                :return-value.sync="props.item.Prg"
                @open="temp(props.item.Prg)"
                @save="guardarPrg(props.item)"
              >
                {{ props.item.Prg }}
                <template v-slot:input>
                  <v-text-field
                    v-model="props.item.Prg"
                    label="Editar"
                    single-line
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:item.modal="props">
              <div class="text-center">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      class="mx-4"
                      v-for="(item, index) in items_menu"
                      :key="index"
                    >
                      <v-list-item-title
                        class="personalizar"
                        @click="opciones(props.item, item.value)"
                      >
                        {{ item.title }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>

            <template v-slot:item.reqveh="props">
              <v-edit-dialog
                :return-value.sync="props.item.reqveh"
                @open="temp(props.item.reqveh)"
                @save="guardarReqveh(props.item)"
              >
                {{ props.item.reqveh }}
                <template v-slot:input>
                  <v-text-field
                    v-model="props.item.reqveh"
                    label="Editar"
                    single-line
                    @click="temp = 1"
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>

            <template v-slot:item.reqhdp="props">
              <p @click="actualizarreqhdp(props.item)">
                {{ props.item.reqhdp || "-" }}
              </p>
            </template>
            <template v-slot:item.reqhsb="props">
              <p @click="actualizarreqhsb(props.item)">
                {{ props.item.reqhsb || "-" }}
              </p>
            </template>
            <template v-slot:item.reqhll="props">
              <p @click="actualizarreqhll(props.item)">
                {{ props.item.reqhll || "-" }}
              </p>
            </template>
            <template v-slot:item.reqhsd="props">
              <p @click="actualizarreqhsd(props.item)">
                {{ props.item.reqhsd || "-" }}
              </p>
            </template>
            <template v-slot:item.reqhyi="props">
              <p @click="actualizarreqhyi(props.item)">
                {{ props.item.reqhyi || "-" }}
              </p>
            </template>
            <template v-slot:item.reqhsi="props">
              <p @click="actualizarreqhsi(props.item)">
                {{ props.item.reqhsi || "-" }}
              </p>
            </template>
            <template v-slot:item.reqhdi="props">
              <p @click="actualizarreqhdi(props.item)">
                {{ props.item.reqhdi || "-" }}
              </p>
            </template>
            <template v-slot:item.reqhcc="props">
              <p @click="actualizarreqhcc(props.item)">
                {{ props.item.reqhcc || "-" }}
              </p>
            </template>

            <!-- FIN DE EDIT DIALOG -->

            <!--TOOLTIPS DE LAS CABECERAS DEL DATATABLE -->
            <template
              v-for="h in headers_datatable_info_despacho"
              v-slot:[`header.${h.value}`]
            >
              <v-tooltip bottom :key="h.tooltip">
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ h.text }} </span>
                </template>
                <span>{{ h.tooltip }}</span>
              </v-tooltip>
            </template>
            <!--FIN TOOLTIPS DE LAS CABECERAS  -->

            <!-- <template v-slot:top>
              <v-dialog max-width="800px">
                <v-card>
                  <v-toolbar dark color="grey darken-3">
                    <v-spacer></v-spacer>
                    <v-toolbar-title>Débito asdAutomático</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>

                  <v-card-actions class="pa-0">
                    <v-spacer></v-spacer>
                    <v-btn class="mb-5" @click="cerrarModal" small dark color="red">Cerrar</v-btn>
                              <v-btn class="mr-14 mb-5"  @click="guardar" small dark color="primary">Guardar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template> -->

            <template v-slot:[`item.modal`]>
              <div class="text-center">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      class="mx-4"
                      v-for="(item, index) in items_menu"
                      :key="index"
                    >
                      <v-list-item-title class="personalizar" @click="abrirModal(index)">
                        {{ item.title }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>
            <template v-slot:item.prioridades_calculadas="props">
              <p v-if="props.item.reqcda == 'V'">
                {{ props.item.prioridades_calculadas }}
              </p>
            </template>

            <!-- DOBLE CLICK -->
            <template v-slot:item.="props">
              <v-edit-dialog
                :return-value.sync="props.item.name"
                @save="save"
                @cancel="cancel"
                @open="open"
                @close="close"
              >
                {{ props.item.name }}
                <template v-slot:input>
                  <v-text-field
                    v-model="props.item.name"
                    :rules="[max25chars]"
                    label="Edit"
                    single-line
                    counter
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:item.iron="props">
              <v-edit-dialog
                :return-value.sync="props.item.iron"
                large
                @save="save"
                @cancel="cancel"
                @open="open"
                @close="close"
              >
                <div>{{ props.item.iron }}</div>
                <template v-slot:input>
                  <div class="mt-4 text-h6">Update Iron</div>
                  <v-text-field
                    v-model="props.item.iron"
                    :rules="[max25chars]"
                    label="Edit"
                    single-line
                    counter
                    autofocus
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
          </v-data-table>
        </template>

        <!-- MODALES DATATABLE -->

        <!-- ORDEN CSC -->
        <v-dialog v-model="modal_orden_csc" max-width="240px">
          <v-card>
            <ModalOrdenCSC />

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" dark small @click="cerrarModal()"> Cerrar </v-btn>
              <v-btn small color="primary" @click="aceptarModal()"> Aceptar </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- CSC TRASLADO -->
        <v-dialog v-model="modal_csc_traslado" max-width="240px">
          <v-card>
            <ModalCSCTraslado />

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" dark small @click="cerrarModal()"> Cerrar </v-btn>
              <v-btn small color="primary" @click="aceptarModal()"> Aceptar </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- VER EQUIPO -->
        <v-dialog v-model="modal_ver_equipo" max-width="1000px">
          <v-card>
            <ModalVerEquipo />

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" dark small @click="cerrarModal()"> Cerrar </v-btn>
              <v-btn small color="primary" @click="aceptarModal()"> Aceptar </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </template>
  </div>
</template>

<script>
import ModalDireccion from "@/components/modales-despacho/ModalDireccion.vue";
import ModalCelNotificaciones from "@/components/modales-despacho/ModalCelNotificaciones.vue";
import ModalAgregarConsulta from "@/components/modales-despacho/ModalAgregarConsulta.vue";
import ModalSignosSintomas from "@/components/modales-despacho/ModalSignosSintomas.vue";
import ModalRegistroLlamada from "@/components/modales-despacho/ModalRegistroLlamada.vue";
import ModalPQRS from "@/components/modales-despacho/ModalPQRS.vue";
import ModalFPago from "@/components/modales-despacho/ModalFPago.vue";
import ModalOrdenCSC from "@/components/modales-despacho/ModalOrdenCSC.vue";
import ModalCSCTraslado from "@/components/modales-despacho/ModalCSCTraslado.vue";
import ModalVerEquipo from "@/components/modales-despacho/ModalVerEquipo.vue";

export default {
  name: "Despacho",

  data: () => ({
    // edit dialog
    snack: false,
    snackColor: "",
    snackText: "",
    max25chars: (v) => v.length <= 25 || "Input too long!",
    pagination: {},

    habilitar: false,
    text_field_disabled: false,
    modal_dir_emergencia: false,
    modal_direccion: false,
    modal_consecutivo: false,
    modal_cel_notificaciones: false,
    modal_evaluacion: false,
    modal_agregar_consulta: false,
    modal_signos_sintomas: false,
    modal_t_consultas: false,
    modal_doc_id: false,
    modal_registro_llamada: false,
    modal_ver_equipo: false,

    modal_orden_csc: false,
    modal_csc_traslado: false,
    modal_forma_pago: false,
    modal_sms: false,
    modal_pqrs: false,
    tabs_antecedentes: null,
    tab: null,
    search: "",
    radicacion: "",
    n_radicacion: "",
    triaje: "",
    consecutivo: "",
    titular: "",
    paciente: "",
    direccion: "",
    telefono: "",
    celular: "",
    edad: "",
    sexo: "",
    doc_id: "",
    f_ing: "2017-07-04",
    f_rop: "2013-07-04",
    tel_de_se_donde_llama: "",
    persona_que_llama: "",
    dir_emergencia: "",
    cel_notificaciones: "",
    signos_sintomas: "",
    evaluacion: "",
    t_consultas: "",
    meses: "",
    r_llamada: "",
    n_ring: "",
    revaloracion: "",
    m_can: "",
    progress_bar: 0,
    novedad: [
      { item: "_INDETERMINADO_" },
      { item: "NORMAL" },
      { item: "FALLECIO" },
      { item: "CONSULTA CANCELADA" },
      { item: "EQUIVOCACIÓN" },
      { item: "PROBLEMA OPERATIVO" },
      { item: "PROB. EXPRESADO POR USUARIO" },
    ],
    novedad_seleccionada: {},
    m_can: [
      { item: "_INDETERMINADO_" },
      { item: "NORMAL" },
      { item: "FALLECIO" },
      { item: "CONSULTA CANCELADA" },
      { item: "EQUIVOCACIÓN" },
      { item: "PROBLEMA OPERATIVO" },
      { item: "PROB. EXPRESADO POR USUARIO" },
    ],
    m_can_seleccionada: {},
    t_serv: [
      { item: "_INDETERMINADO_" },
      { item: "CONSULTA CON DESPACHO" },
      { item: "CONSULTA ATENCIÓN TELEFONICA" },
      { item: "CONSULTA CON TRASLADO" },
      { item: "CONSULTA CANCELADA" },
      { item: "EQUIVOCACIÓN" },
      { item: "TRASLADO PROGRAMADO" },
      { item: "CONSULTA PROGRAMADA" },
      { item: "SOAT (atentido por)" },
      { item: "AREA PROTEGIDA" },
      { item: "RECIPROCIDAD" },
      { item: "ORIENTACIÓN TELEFONICA" },
    ],
    t_serv_seleccionado: {},
    eps: [{ item: "SEGURO SOCIAL" }, { item: "MEDIMAS" }, { item: "SALUCOOP" }],
    eps_seleccionada: {},
    sintoma_seleccionada: {},
    sintomas: [],
    headers_tab: ["ANTECEDENTES", "N.CONSULTA", "N.OPERADOR"],
    textarea_antecedentes: "",
    textarea_n_consulta: "",
    textarea_n_operador: "",
    headers_t_consultas: [
      { text: "N° HISTORIA", value: "reqcsc" },
      { text: "FECHA", value: "reqdia" },
      { text: "HORA", value: "reqhor" },
      { text: "HORA LL", value: "reqhll" },
      { text: "MEDICO", value: "medico" },
      { text: "MOTIVO", value: "reqsig" },
      { text: "DIAGNOSTICO", value: "diagnostico" },
      { text: "AUXILIAR", value: "enfermero" },
      { text: "CONDUCTOR", value: "conductor" },
      { text: "TIPO SERVICIO", value: "tpsnom" },
    ],
    data_t_consultas: [],
    headers_datatable_doc_id: [
      { text: "Usuario", value: "usuario" },
      { text: "Documento", value: "documento" },
      { text: "Beneficiario", value: "beneficiario" },
      { text: "ConAnt", value: "con_ant" },
      { text: "TNitAnt", value: "tnitant" },
      { text: "Titular Anterior", value: "titular_anterior" },
      { text: "TnitNvo", value: "tnitnvo" },
      { text: "Estado Anterior", value: "estado_anterior" },
      { text: "Estado Nuevo", value: "estado_nuevo" },
      { text: "Actividad Realizada", value: "actividad_realizada" },
      { text: "Fecha", value: "fecha" },
    ],
    data_datatable_doc_id: [
      {
        usuario: "Pedro",
        documento: "123123123",
        beneficiario: "beneficiario",
        con_ant: "cont ant",
        tnitant: "324234234",
        titular_anterior: "titulo anterior",
        tnitnvo: "345345435",
        estado_anterior: "estado anterior",
        estado_nuevo: "estado nuevo",
        actividad_realizada: "actividad",
        fecha: "2019-12-02",
      },
    ],
    headers_datatable_sms: [
      { text: "ID SMS", value: "id_sms" },
      { text: "Tipo SMS", value: "tipo_sms" },
      { text: "Mensaje", value: "mensaje" },
      { text: "Celular", value: "celular" },
      { text: "Nombre Beneficiario", value: "nombre_beneficiario" },
      { text: "Documento Titular", value: "documento_titular" },
      { text: "Nombre titular", value: "nombre_titular" },
      { text: "Nit Grupo", value: "nit_grupo" },
      { text: "Nombre Grupo", value: "nombre_grupo" },
      { text: "Fecha Envio", value: "fecha_envio" },
      { text: "Usuario que envia SMS", value: "usuario_que_envia_sms" },
    ],
    data_datatable_sms: [
      {
        id_sms: "2",
        tipo_sms: "123123123",
        mensaje: "mensajeasdasdasdasd",
        celular: "3123223235",
        nombre_beneficiario: "Roberto",
        documento_titular: "32423423 ",
        nombre_titular: "Carlos",
        nit_grupo: "542353245",
        nombre_grupo: "nombre grupo",
        fecha_envio: "2019-12-02",
        usuario_que_envia_sms: "Pedro paramo",
      },
    ],
    headers_datatable_info_despacho: [
      { text: "", value: "modal" },
      { text: "CSC", value: "reqcsc", tooltip: "Consecutivo" },
      { text: "Valh", value: "reqreg", tooltip: "Validación Historia Clinica" },
      { text: "Fecha", value: "reqdia", tooltip: "Fecha" },
      { text: "Hora", value: "reqhor", tooltip: "Hora Llamada" },
      { text: "mov", value: "reqveh", tooltip: "Movil" },
      { text: "A", value: "reqcda", tooltip: "Triage" },
      { text: "Pr", value: "prioridades_calculadas", tooltip: "Prioridad" },
      { text: "Z", value: "WebMapingBarrioCSC", tooltip: "Barrio/Comuna/Zona" },
      { text: "Prg", value: "Prg", tooltip: "Programacion movil" },
      { text: "TSC", value: "Tiempo salida consulta", tooltip: "Tiempo salida consulta" },
      { text: "Desp", value: "reqhdp", tooltip: "Hora Despacho" },
      { text: "Sale", value: "reqhsb", tooltip: "Sale" },
      { text: "llega", value: "reqhll", tooltip: "Llega" },
      { text: "Sale D", value: "reqhsd", tooltip: "Sale D" },
      { text: "Ll inst", value: "reqhyi", tooltip: "Llegada institucion" },
      { text: "S Inst", value: "reqhsi", tooltip: "Sale institución" },
      { text: "Disp", value: "reqhdi", tooltip: "Disponible" },
      { text: "Canc", value: "reqhcc", tooltip: "Cancela" },
      { text: "Rrll", value: "rellamadas", tooltip: "Rellamada" },
    ],
    data_datatable_info_despacho: [],

    items_menu: [
      { title: "Terminar Consulta", value: 1 },
      { title: "Orden CSC" },
      { title: "CSC Traslado" },
      { title: "Ver Equipo" },
      { title: "Información" },
      { title: "Enviar Correo" },
      { title: "Hora Llegada Base" },
    ],

    prioridades: [],
    porcentajes: {
      reqhdp: 5,
      HConsCSC: 5,
      reqhll: 10,
      HCreaHC: 10,
      HMotAnte: 20,
      HExaFis: 10,
      Htratamiento: 10,
      HFirma: 15,
      HCodiValo: 8,
    },
    TEMP: null,
    reqvehDisabled: true,
  }),
  components: {
    ModalDireccion,
    ModalCelNotificaciones,
    ModalAgregarConsulta,
    ModalSignosSintomas,
    ModalRegistroLlamada,
    ModalPQRS,
    ModalFPago,
    ModalOrdenCSC,
    ModalCSCTraslado,
    ModalVerEquipo,
  },

  watch: {
    triaje: function (value) {
      if (value == "") {
        this.text_field_disabled = false;
        this.habilitar = false;
        console.log(value, "habilitar:", this.habilitar);
      } else {
        this.text_field_disabled = true;
        this.habilitar = true;
        console.log(value, "habilitar:", this.habilitar);
      }
    },
  },

  methods: {
    temp(value) {
      this.TEMP = value;
    },
    eventoBlurTelefono() {
      Axios.post("/actualizarBeneficiario", {
        campo: "bentel",
        bentel: this.telefono,
        bendoc: this.doc_id,
      }).then(() => {
        //Posible Señal De Guardado
      });
    },
    eventoBlurCelular() {
      Axios.post("/actualizarBeneficiario", {
        campo: "bencel",
        bencel: this.celular,
        bendoc: this.doc_id,
      }).then(() => {
        //Posible Señal De Guardado
      });
    },
    eventoBlurTelefonoDeDondeLlama() {
      Axios.post("/actualizarServicio", {
        campo: "reqtel",
        reqtel: this.tel_de_se_donde_llama,
        csc: this.consecutivo,
      }).then(() => {
        //Posible Señal De Guardado
      });
    },
    eventoBlurAntecedentes() {
      Axios.post("/actualizarBeneficiario", {
        campo: "bentxt",
        bentxt: this.textarea_antecedentes,
        bendoc: this.doc_id,
      }).then(() => {
        //Posible Señal De Guardado
      });
    },
    eventoBlurNConsulta() {
      Axios.post("/actualizarServicio", {
        campo: "reqtxo",
        reqtxo: this.textarea_n_consulta,
        csc: this.consecutivo,
      }).then(() => {
        //Posible Señal De Guardado
      });
    },
    eventoBlurNOperador() {
      Axios.post("/actualizarBeneficiario", {
        campo: "infase",
        infase: this.textarea_n_operador,
        bendoc: this.doc_id,
      }).then(() => {
        //Posible Señal De Guardado
      });
    },
    eventoBlurPersonaQueLlama() {
      Axios.post("/actualizarServicio", {
        campo: "reqper",
        reqper: this.persona_que_llama,
        csc: this.consecutivo,
      }).then(() => {
        //Posible Señal De Guardado
      });
    },
    eventoBlurDireccionEmergencia() {
      Axios.post("/actualizarServicio", {
        campo: "reqdir",
        reqdir: this.dir_emergencia,
        csc: this.consecutivo,
      }).then(() => {
        //Posible Señal De Guardado
      });
    },
    eventoBlurEvaluacion() {
      Axios.post("/actualizarServicio", {
        campo: "reqcdf",
        reqcdf: this.evaluacion,
        csc: this.consecutivo,
      }).then(() => {
        //Posible Señal De Guardado
      });
    },
    eventoBlurSignosYSintomas() {
      Axios.post("/actualizarServicio", {
        campo: "reqsig",
        reqsig: this.signos_sintomas,
        csc: this.consecutivo,
      }).then(() => {
        //Posible Señal De Guardado
      });
    },
    eventoBlurCelNotificaciones() {
      Axios.post("/actualizarServicio", {
        campo: "celNotificacion",
        celNotificacion: this.cel_notificaciones,
        csc: this.consecutivo,
      }).then(() => {
        //Posible Señal De Guardado
      });
    },
    eventBlurTriaje() {
      Axios.post("/actualizarServicio", {
        campo: "reqcda",
        reqcda: this.triaje,
        csc: this.consecutivo,
      }).then(() => {
        //Posible Señal De Guardado
      });
    },
    eventoBlurRevaloracion() {
      Axios.post("/actualizarServicio", {
        campo: "reqcda2",
        reqcda2: this.revaloracion,
        csc: this.consecutivo,
      }).then(() => {
        //Posible Señal De Guardado
      });
    },
    eventoBlurNovedad() {
      Axios.post("/actualizarServicio", {
        campo: "reqnov",
        reqnov: this.novedad_seleccionada,
        csc: this.consecutivo,
      }).then(() => {
        //Posible Señal De Guardado
      });
    },
    eventoBlurTserv() {
      Axios.post("/actualizarServicio", {
        campo: "reqtps",
        reqtps: this.t_serv_seleccionado,
        csc: this.consecutivo,
      }).then(() => {
        //Posible Señal De Guardado
      });
    },
    eventoBlurEPS() {
      Axios.post("/actualizarBeneficiario", {
        campo: "beneps",
        beneps: this.eps_seleccionada,
        bendoc: this.doc_id,
      }).then(() => {
        //Posible Señal De Guardado
      });
    },
    eventoBlurSintomas() {
      console.log("eventoBlurSintomas");
    },
    eventoBlurMcan() {
      Axios.post("/actualizarServicio", {
        campo: "cansrv",
        cansrv: this.m_can_seleccionada,
        csc: this.consecutivo,
      }).then(() => {
        //Posible Señal De Guardado
      });
    },

    // FUNCIONES DEL EDIT DIALOG -->

    guardarPrg(item) {
      Swal.fire({
        title: "Quieres cambiar la Programación Movil?",
        showDenyButton: true,
        confirmButtonText: "Guardar",
        denyButtonText: `No Guardar`,
        reverseButtons: true,
        allowEnterKey: false,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          Axios.post("/actualizarServicio", {
            campo: "Prg",
            Prg: item.Prg,
            csc: item.reqcsc,
          }).then(() => {
            //Posible Señal De Guardado
          });
        } else if (result.isDenied) {
          item.Prg = this.TEMP;
          Swal.fire("Cambio Abortado", "", "info");
        }
      });
    },
    guardarReqhdi() {
      console.log("Guardar Reqhdi");
    },

    guardarReqveh(item) {
      console.log(item);
      Swal.fire({
        title: "Quieres cambiar la movil?",
        showDenyButton: true,
        confirmButtonText: "Guardar",
        denyButtonText: `No Guardar`,
        reverseButtons: true,
        allowEnterKey: false,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          Axios.post("/actualizarServicio", {
            campo: "reqveh",
            reqveh: item.reqveh,
            csc: item.reqcsc,
          }).then(() => {
            //Posible Señal De Guardado
          });
        } else if (result.isDenied) {
          item.reqveh = this.TEMP;
          Swal.fire("Cambio Abortado", "", "info");
        }
      });
    },

    // FUNCIONES QUE PERMITEN EDITAR CAMPOS DEL DATATABLE Y GUARDARLOS
    guardarReqhdp() {
      console.log("guardar reqhdp");
    },

    guardarReqhsb() {
      console.log("Guardar reqhsb");
    },

    guardarReqhll() {
      console.log("Guardar reqhll");
    },

    guardarReqhyi() {
      console.log("Guardar reqhyi");
    },

    guardarReqhsi() {
      console.log("Guardar Reqhsi");
    },

    guardarReqhdi() {
      console.log("Guardar reqhdi");
    },

    guardarReqhcc() {
      console.log("Guardar reqhcc");
    },

    save() {
      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Data saved";
    },
    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Canceled";
    },
    open() {
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Dialog opened";
    },
    close() {
      console.log("Dialog closed");
    },

    cerrarModal() {
      this.modal_dir_emergencia = false;
      this.modal_direccion = false;
      this.modal_consecutivo = false;
      this.modal_cel_notificaciones = false;
      this.modal_evaluacion = false;
      this.modal_agregar_consulta = false;
      this.modal_signos_sintomas = false;
      this.modal_t_consultas = false;
      this.modal_doc_id = false;
      this.modal_registro_llamada = false;
      this.modal_pqrs = false;
      this.modal_sms = false;
      this.modal_orden_csc = false;
      this.modal_csc_traslado = false;
      this.modal_ver_equipo = false;
      this.modal_forma_pago = false;
    },
    aceptarModal() {
      this.modal_dir_emergencia = false;
      this.modal_direccion = false;
      this.modal_consecutivo = false;
      this.modal_cel_notificaciones = false;
      this.modal_evaluacion = false;
      this.modal_agregar_consulta = false;
      this.modal_signos_sintomas = false;
      this.modal_t_consultas = false;
      this.modal_doc_id = false;
      this.modal_registro_llamada = false;
      this.modal_pqrs = false;
      this.modal_sms = false;
      this.modal_forma_pago = false;
    },
    abrirModal(index) {
      let indice = this.items_menu[index].title;
      // this.modal_agregar_consulta = true;

      switch (indice) {
        case "Terminar Consulta":
          // Aqui va el codigo
          break;
        case "Orden CSC":
          this.modal_orden_csc = true;
          break;
        case "CSC Traslado":
          this.modal_csc_traslado = true;
          break;
        case "Ver Equipo":
          this.modal_ver_equipo = true;
          break;
        case "Información":
          // Aqui va el codigo
          break;
        case "Enviar Correo":
          // Aqui va el codigo
          break;
        case "Hora Llegada Base":
          // Aqui va el codigo
          break;
      }
    },
    handleClick(value) {
      Axios.get("/rellamadas", {
        params: {
          consecutivo: value.reqcsc,
        },
        headers: {},
      }).then((response) => {
        this.r_llamada = response.data.length;
      });
      this.revaloracion = value.reqcda2;
      this.triaje = value.reqcda;
      this.radicacion = value.grunom;
      this.n_radicacion = value.grugru;
      this.consecutivo = value.reqcsc;
      this.titular = value.titnom;
      this.paciente = value.bennom;
      this.direccion = value.direccionCompleta || value.bendir;
      this.celular = value.bencel;
      this.telefono = value.bentel;
      this.edad = value.edad;
      this.sexo = value.bensex;
      this.doc_id = value.bendoc;
      this.tel_de_se_donde_llama = value.reqtel;
      this.persona_que_llama = value.reqper;
      this.dir_emergencia = value.reqdir;
      this.cel_notificaciones = value.celNotificacion;
      this.eps_seleccionada = value.beneps;
      this.signos_sintomas = value.reqsig;
      this.evaluacion = value.reqcdf;
      this.sintoma_seleccionada = value.reqcdf;
      this.t_consultas = value.total_servicios;

      this.n_ring = value.retiros;
      this.t_serv_seleccionado = value.reqtps;
      this.novedad_seleccionada = value.reqnov;
      this.m_can_seleccionada = value.cansrv;
      this.progress_bar = 0;
      let acumulador = 0;
      for (const prop in this.porcentajes) {
        if (value[prop] !== null && value[prop] !== "") {
          acumulador += this.porcentajes[prop];
        }
      }
      this.progress_bar = acumulador;
      this.textarea_antecedentes = value.bentxt;
      this.textarea_n_consulta = value.reqtxo;
      this.textarea_n_operador = value.infase;
    },
    buscar() {
      this.data_datatable_info_despacho = [];
      this.$store.state.stateLoad = true;

      Axios.get("/servicios-activos", {
        headers: {},
      }).then((response) => {
        this.data_datatable_info_despacho = response.data;
        console.log("Esta es la data", this.data_datatable_info_despacho);
        this.data_datatable_info_despacho.forEach((element) => {
          element["reqvehDisabled"] = true;
          let temp = element.prioridades_calculadas;
          let string = typeof temp;
          let COR = false;
          if (string == "string") {
            COR = temp.includes("COR");
          }
          if (COR) {
            temp = temp.split(" ")[1];
          }

          if (
            temp >= this.prioridades[0].rango_inicial &&
            temp < this.prioridades[0].rango_final
          ) {
            element.prioridades_calculadas = this.prioridades[0].prioridad;
          } else if (
            temp >= this.prioridades[1].rango_inicial &&
            temp < this.prioridades[1].rango_final
          ) {
            element.prioridades_calculadas = this.prioridades[1].prioridad;
          } else if (
            temp >= this.prioridades[2].rango_inicial &&
            temp < this.prioridades[2].rango_final
          ) {
            element.prioridades_calculadas = this.prioridades[2].prioridad;
          } else if (
            temp >= this.prioridades[3].rango_inicial &&
            temp < this.prioridades[3].rango_final
          ) {
            element.prioridades_calculadas = this.prioridades[3].prioridad;
          } else if (
            temp >= this.prioridades[4].rango_inicial &&
            temp < this.prioridades[4].rango_final
          ) {
            element.prioridades_calculadas = this.prioridades[4].prioridad;
          } else if (temp >= this.prioridades[5].rango_inicial) {
            element.prioridades_calculadas = this.prioridades[5].prioridad;
          }
          if (COR) {
            element.prioridades_calculadas = "COR " + element.prioridades_calculadas;
          }
        });
        this.$store.state.stateLoad = false;
      });
    },
    totalServiciosDespacho() {
      Axios.get("/totalServiciosDespacho", {
        params: {
          documento: this.doc_id,
        },
        headers: {},
      }).then((response) => {
        this.data_t_consultas = response.data;
      });
    },
    actualizarreqhdp(item) {
      console.log(item);
      if (item.reqhdp == null) {
        Swal.fire({
          title: "Deseas registrar la hora de despacho?",
          showDenyButton: true,
          confirmButtonText: "Si",
          denyButtonText: `No`,
          reverseButtons: true,
          allowEnterKey: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            Axios.post("/actualizarServicio", {
              campo: "reqhdp",
              csc: item.reqcsc,
            }).then((response) => {
              console.log(response);
              item.reqhdp = response.data[0].hora;
              //Posible Señal De Guardado
            });
          } else if (result.isDenied) {
            Swal.fire("Cambio Abortado", "", "info");
          }
        });
      } else {
        Swal.fire("Error, ya registro hora", "", "error");
      }
    },
    actualizarreqhsb(item) {
      console.log(item);
      if (item.reqhsb == null) {
        Swal.fire({
          title: "Deseas registrar la hora de Salida?",
          showDenyButton: true,
          confirmButtonText: "Si",
          denyButtonText: `No`,
          reverseButtons: true,
          allowEnterKey: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            Axios.post("/actualizarServicio", {
              campo: "reqhsb",
              csc: item.reqcsc,
            }).then((response) => {
              console.log(response);
              item.reqhsb = response.data[0].hora;
              //Posible Señal De Guardado
            });
          } else if (result.isDenied) {
            Swal.fire("Cambio Abortado", "", "info");
          }
        });
      } else {
        Swal.fire("Error, ya registro hora", "", "error");
      }
    },
    actualizarreqhll(item) {
      console.log(item);
      if (item.reqhll == null) {
        Swal.fire({
          title: "Deseas registrar la hora de Llegada?",
          showDenyButton: true,
          confirmButtonText: "Si",
          denyButtonText: `No`,
          reverseButtons: true,
          allowEnterKey: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            Axios.post("/actualizarServicio", {
              campo: "reqhll",
              csc: item.reqcsc,
            }).then((response) => {
              console.log(response);
              item.reqhll = response.data[0].hora;
              //Posible Señal De Guardado
            });
          } else if (result.isDenied) {
            Swal.fire("Cambio Abortado", "", "info");
          }
        });
      } else {
        Swal.fire("Error, ya registro hora", "", "error");
      }
    },
    actualizarreqhsd(item) {
      console.log(item);
      if (item.reqhsd == null) {
        Swal.fire({
          title: "Deseas registrar la hora de Salida De Insitución?",
          showDenyButton: true,
          confirmButtonText: "Si",
          denyButtonText: `No`,
          reverseButtons: true,
          allowEnterKey: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            Axios.post("/actualizarServicio", {
              campo: "reqhsd",
              csc: item.reqcsc,
            }).then((response) => {
              console.log(response);
              item.reqhsd = response.data[0].hora;
              //Posible Señal De Guardado
            });
          } else if (result.isDenied) {
            Swal.fire("Cambio Abortado", "", "info");
          }
        });
      } else {
        Swal.fire("Error, ya registro hora", "", "error");
      }
    },
    actualizarreqhyi(item) {
      console.log(item);
      if (item.reqhyi == null) {
        Swal.fire({
          title: "Deseas registrar la hora de Llegada De Insitución?",
          showDenyButton: true,
          confirmButtonText: "Si",
          denyButtonText: `No`,
          reverseButtons: true,
          allowEnterKey: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            Axios.post("/actualizarServicio", {
              campo: "reqhyi",
              csc: item.reqcsc,
            }).then((response) => {
              console.log(response);
              item.reqhyi = response.data[0].hora;
              //Posible Señal De Guardado
            });
          } else if (result.isDenied) {
            Swal.fire("Cambio Abortado", "", "info");
          }
        });
      } else {
        Swal.fire("Error, ya registro hora", "", "error");
      }
    },
    actualizarreqhsi(item) {
      console.log(item);
      if (item.reqhsi == null) {
        Swal.fire({
          title: "Deseas registrar la hora de Salida De Insitución?",
          showDenyButton: true,
          confirmButtonText: "Si",
          denyButtonText: `No`,
          reverseButtons: true,
          allowEnterKey: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            Axios.post("/actualizarServicio", {
              campo: "reqhsi",
              csc: item.reqcsc,
            }).then((response) => {
              console.log(response);
              item.reqhsi = response.data[0].hora;
              //Posible Señal De Guardado
            });
          } else if (result.isDenied) {
            Swal.fire("Cambio Abortado", "", "info");
          }
        });
      } else {
        Swal.fire("Error, ya registro hora", "", "error");
      }
    },
    actualizarreqhdi(item) {
      console.log(item);
      if (item.reqhdi == null) {
        Swal.fire({
          title: "Deseas registrar la hora de Disponible?",
          showDenyButton: true,
          confirmButtonText: "Si",
          denyButtonText: `No`,
          reverseButtons: true,
          allowEnterKey: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            Axios.post("/actualizarServicio", {
              campo: "reqhdi",
              csc: item.reqcsc,
            }).then((response) => {
              console.log(response);
              item.reqhdi = response.data[0].hora;
              //Posible Señal De Guardado
            });
          } else if (result.isDenied) {
            Swal.fire("Cambio Abortado", "", "info");
          }
        });
      } else {
        Swal.fire("Error, ya registro hora", "", "error");
      }
    },
    actualizarreqhcc(item) {
      console.log(item);
      if (item.reqhcc == null) {
        Swal.fire({
          title: "Deseas registrar la hora de Cancelacion?",
          showDenyButton: true,
          confirmButtonText: "Si",
          denyButtonText: `No`,
          reverseButtons: true,
          allowEnterKey: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            Axios.post("/actualizarServicio", {
              campo: "reqhcc",
              csc: item.reqcsc,
            }).then((response) => {
              console.log(response);
              item.reqhcc = response.data[0].hora;
              //Posible Señal De Guardado
            });
          } else if (result.isDenied) {
            Swal.fire("Cambio Abortado", "", "info");
          }
        });
      } else {
        Swal.fire("Error, ya registro hora", "", "error");
      }
    },
    opciones(item, opcion) {
      switch (opcion) {
        case 1:
          Swal.fire({
            title: "Deseas Finalizar la consulta?",
            showDenyButton: true,
            confirmButtonText: "Si",
            denyButtonText: `No`,
            reverseButtons: true,
            allowEnterKey: false,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              Axios.post("/actualizarServicio", {
                campo: "reqenp",
                csc: item.reqcsc,
              }).then(() => {
                Swal.fire("Finalizado!", "", "success");
              });
            } else if (result.isDenied) {
              Swal.fire("Cambio Abortado", "", "info");
            }
          });

          break;

        default:
          break;
      }
    },
  },
  async created() {
    this.$store.state.stateLoad = true;

    await Axios.get("/prioridades", {}).then((response) => {
      this.prioridades = response.data;
    });
    await Axios.get("/eps", {}).then((response) => {
      this.eps = response.data;
    });
    await Axios.get("/sintomas", {}).then((response) => {
      this.sintomas = response.data;
    });
    await Axios.get("/tipo_servicio", {}).then((response) => {
      this.t_serv = response.data;
    });
    await Axios.get("/novedades", {}).then((response) => {
      this.novedad = response.data;
      this.m_can = response.data;
    });
    await this.buscar();
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");

/* FONT-AWESOME */
@import url("https://pro.fontawesome.com/releases/v5.10.0/css/all.css");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

textarea {
  width: 100%;
  outline: none;
  padding: 10px 12px 11px 1px;
  background: rgb(238, 238, 238);
  height: 85px;
  margin-top: 10px;
}

td {
  border: 1px solid #dddddd;
  padding: 4px 100px 4px 7px;
}
.personalizar:hover {
  background: rgb(235, 233, 233) !important;
  cursor: pointer;
}

.personalizar {
  padding: 8px 13px 10px 10px;
}

.text-area {
  font-size: 12px;
}

.porcentaje_progress_bar {
  font-size: 11px;
}

.contenedor {
  padding: 8px;
}

/* TEXT-FIELD */

input[type="text"] {
  border: 1px solid rgba(197, 197, 197, 0.39);
  padding: 4px 8px;
  border-radius: 5px;
}

/* input[type=text]:focus {
    outline: none;

    box-shadow: 0 0 0 1px rgb(81, 200, 255);
  
} */

.text-field {
  border: 1px solid rgb(63, 12, 12);
  outline: none;
  border-radius: 4px;
  padding-left: 4px;
  width: 100%;
  background-color: rgb(255, 255, 255);
  border: 0;
  font-size: 0.6rem;
  font-family: "Roboto", sans-serif;
  height: 20px;
  color: rgb(59, 58, 56);
}

.text_field_disabled {
  border: 1px solid rgb(63, 12, 12);
  outline: none;
  border-radius: 4px;
  padding-left: 4px;
  width: 100%;
  background-color: rgb(235, 235, 235);
  border: 0;
  font-size: 0.6rem;
  font-family: "Roboto", sans-serif;
  height: 20px;
  color: rgb(59, 58, 56);
}

.etiqueta {
  font-size: 0.6rem;
  font-family: "Roboto", sans-serif;
}

/* SELECT */
select:focus {
  outline: none;

  box-shadow: 0 0 0 1.4px rgb(139, 193, 255);
  /* Full freedom. (works also with border-radius) */
}

select {
  width: 100%;
  outline: none;
  border: 1px solid rgb(231, 230, 230);
  border-radius: 4px;
  font-size: 0.6rem;
  padding-left: 4px;
  height: 20px;
}

.custom-select {
  position: relative;
}

.custom-arrow {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  background: rgba(255, 255, 255, 0);
  height: 10%;
  width: 1.2rem;
  pointer-events: none;
  padding: 0px;
}

.arrow-down {
  font-size: 12px;
}

select option {
  border-radius: 10px;
  font-size: 13px;
  border: 0px;
}

.text-area {
  font-size: 10px !important;
  padding: 0px;
  margin: 0px;
}

/* Boton */
.boton {
  background: rgba(10, 173, 160, 0.993);
  color: white;
  padding: 2px 10px;
  border-radius: 4px;
  font-size: 12px;
  margin-left: 2px;
}

.boton-pequeño {
  background: rgba(10, 173, 160, 0.993);
  color: white;
  padding: 2px 3px;
  border-radius: 4px;
  font-size: 12px;

  width: 20px;
}

.boton:hover {
  background: rgba(11, 190, 176, 0.993);
}

.boton-pequeño:hover {
  background: rgba(11, 190, 176, 0.993);
}

.bandera {
  background: rgba(10, 151, 245, 0.993);
  color: white;
  padding: 2px 10px !important;
  border-radius: 4px;
  font-size: 10px;
  margin: 2px;
}

.bandera:hover {
  background: rgba(54, 167, 241, 0.993);
}

.banderas {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  margin-left: 20px !important;
  flex-wrap: nowrap !important;
}

.v-tabs--fixed-tabs > .v-tabs-bar .v-tab {
  font-size: 10px;
}

@media only screen and (max-width: 1264px) and (min-width: 960px) {
  .bandera {
    background: rgba(10, 151, 245, 0.993);
    color: white;
    padding: 0px 0px;
    border-radius: 4px;
    font-size: 8px;
    margin: 2px;
    width: 29px;
    height: 17px;
  }

  .bandera2 {
    width: 46px;
  }

  .fechas {
    font-size: 8px;
    width: 100px;
  }
}

@media only screen and (max-width: 960px) and (min-width: 600px) {
  .bandera {
    background: rgba(10, 151, 245, 0.993);
    color: white;
    padding: 0px 0px;
    border-radius: 4px;
    font-size: 8px;
    margin: 2px;
    width: 29px;
    height: 17px;
  }

  .bandera2 {
    width: 46px;
  }

  .fechas {
    font-size: 8px;
    width: 100px;
  }
}

@media only screen and (max-width: 960px) {
  .boton {
    background: rgba(10, 173, 160, 0.993);
    color: white;
    padding: 2px 3px;
    border-radius: 4px;
    font-size: 12px;

    width: 20px;
    margin-left: 4px;
  }

  .boton-pequeño {
    background: rgba(10, 173, 160, 0.993);
    color: white;
    padding: 2px 3px;
    border-radius: 4px;
    font-size: 12px;
    width: 20px;
  }
  .v-tabs-slider-wrapper {
    font-size: 10px;
    width: 10px;
  }
}
</style>
