<template>
  <div>
    <v-app>
      <v-app-bar app dark class="teal">
        <v-toolbar-title>Vitalsoft</v-toolbar-title>
      </v-app-bar>

      <v-content>
        <v-card width="500" class="mx-auto mt-9">
          <v-card-title>Login</v-card-title>
          <v-card-text>
            <v-text-field
              v-model="username"
              label="Username"
              prepend-icon="mdi-account-circle"
            />
            <v-text-field
              v-model="password"
              label="Password"
              :type="showPassword ? 'text' : 'password'"
              prepend-icon="mdi-lock"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
            />
          </v-card-text>

          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="info" @click="login">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-content>
      <!--<div>
        <object
          :data="pdfLink"
          type="application/pdf"
          width="500"
          height="678"
        >
          <iframe
            :src="pdfLink"
            width="500"
            height="678"
          >
            <p>This browser does not support PDF!</p>
          </iframe>
        </object>
      </div>-->
    </v-app>
    <!-- <button @click="login">LOGIN</button> -->
    <!-- <router-view /> -->

    <!-- <button @click="$router.push({ name: 'prueba' })">CLICK</button> -->

    <!-- <button @click="$router.push({ name: 'prueba2' })">CLICK</button> -->
  </div>
</template>

<script>
export default {
  name: "Login",
  beforeCreate() {
    if (this.$store.state.token != "") {
      this.$router.push({
        name: "Home",
      });
    }
  },
  data: () => ({
    showPassword: false,
    username: "",
    password: "",
    // pdfLink: require("../assets/prueba.pdf"),
  }),
  methods: {
    login() {
      this.$store.state.stateLoad = true;
      Axios.post("login", {
        documento: this.username,
        password: this.password,
      })
        .then((response) => {
          this.$store.state.stateLoad = false;
          console.log(response.data);
          // this.$store.state.token = response.data.token;
          response.data.credentials.forEach((element) => {
            this.$store.commit("activar", element.name);
            this.$store.commit("activarCategoria", element.category_id);
          });
          this.$store.commit("token", response.data.token);
          this.$store.commit("user", response.data.user);
          Axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${response.data.token}`;

          this.$router.push({
            path: "/home",
          });
        })
        .catch((error) => {
          switch (error.response.status) {
            case 422:
              Swal.fire({
                icon: "error",
                title: "Faltan datos! ",
                text: "Por favor revise sus credenciales",
              });
              break;

            default:
              break;
          }
        });
    },
  },
  mounted() {
    this.$store.commit("desactivar");
  },
};
</script>

<style></style>
