import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";

import inicio from "../subviews/Home";
import FullCalendarVista from "../subviews/FullCalendarVista";
import Despacho from "../subviews/Despacho";

import CicloActivo from "../subviews/CicloActivo";
import PDF from "../subviews/PDF";
import registrar from "../subviews/Register.vue";
import HistoricoDesparasitacion from "../subviews/HistoricoDesparasitacion";
import Prueba from "../subviews/Prueba";
import IndicadoresDesempeno from "../subviews/IndicadoresDesempeno";
import RegistrarEntregaDesparasitacion from "../subviews/RegistrarEntregaDesparasitacion";
import GestionarCarteraVencida from '../subviews/GestionarCarteraVencida'
import RevisionFactura from '../subviews/RevisionFactura'
import InformeDian from '../subviews/InformeDian'
import RevisionAnexos from '../subviews/RevisionAnexos'
import GestionarCarteraVencida2 from '../subviews/GestionarCarteraVencida2'
import Agregar from '../subviews/Agregar'
import TiempoPromedioConsulta from '../subviews/TiempoPromedioConsulta'
import ExportarContables from '../subviews/ExportarContables'
import HistoriasClinicasMascotas from '../subviews/HistoriasClinicasMascotas'
import store from "../store";
Vue.use(VueRouter);
Vue.filter("numbers", function (value) {
  value = value.toString().replace(/[^0-9]+/g, "");
  while (/(\d+)(\d{3})/.test(value.toString())) {
    value = value.toString().replace(/(\d+)(\d{3})/, "$1" + "," + "$2");
  }
  return value;
});
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/home",
        name: "inicio",
        component: inicio,
      },
      {
        path: "/calendar",
        name: "calendar",
        component: FullCalendarVista,
        beforeEnter: (to, from, next) => {
          if(store.state.credentials['Agenda Personal']){
            Axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${store.state.token}`;
            next()
          }else{
            next({name: '404'})
          }
        }

      },
      {
        path: "/indicadoresdesempeno",
        name: "indicadoresdesempeno",
        component: IndicadoresDesempeno,
        beforeEnter: (to, from, next) => {
          if(store.state.credentials['Indicadores Desempeno']){
            Axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${store.state.token}`;
            next()
          }else{
            next({name: '404'})
          }
        }
      },
      
      {
        path: "/gestionarcarteravencidagerencia",
        name: "gestionarcarteravencida",
        component: GestionarCarteraVencida,
        beforeEnter: (to, from, next) => {
          if(store.state.credentials['Cartera Vencida Gerencia']){
            Axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${store.state.token}`;
            next()
          }else{
            next({name: '404'})
          }
        }
      },
      {
        path: "/gestionarcarteravencida",
        name: "gestionarcarteravencida2",
        component: GestionarCarteraVencida2,
        beforeEnter: (to, from, next) => {
          if(store.state.credentials['Cartera Vencida']){
            Axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${store.state.token}`;
            next()
          }else{
            next({name: '404'})
          }
        }
      },
      {
        path: "/revisionfacturas",
        name: "revisionfacturas",
        component: RevisionFactura,
        beforeEnter: (to, from, next) => {
          if(store.state.credentials['Revision Facturas']){
            Axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${store.state.token}`;
            next()
          }else{
            next({name: '404'})
          }
        }
      },
      {
        path: "/revisionanexos",
        name: "revisionanexos",
        component: RevisionAnexos,
        beforeEnter: (to, from, next) => {
          if(store.state.credentials['Revision Anexos']){
            Axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${store.state.token}`;
            next()
          }else{
            next({name: '404'})
          }
        }
      },
      {
        path: "/informedian",
        name: "informedian",
        component: InformeDian,
        beforeEnter: (to, from, next) => {
          if(store.state.credentials['Informe Dian']){
            Axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${store.state.token}`;
            next()
          }else{
            next({name: '404'})
          }
        }
      },
      {
        path: "/despacho",
        name: "despacho",
        component: Despacho,
      },
      /* {
        path: "/agregar",
        name: "agregar",
        component: Agregar, ADMIN
      }, */
      {
        path: "/tiempopromedioconsulta",
        name: "tiempopromedioconsulta",
        component: TiempoPromedioConsulta,
        beforeEnter: (to, from, next) => {
          if(store.state.credentials['Tiempo Promedio']){
            Axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${store.state.token}`;
            next()
          }else{
            next({name: '404'})
          }
        }
      },
      {
        path: "/prueba",
        name: "prueba",
        component: Prueba,
        beforeEnter: (to, from, next) => {
          if(true){
            Axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${store.state.token}`;
            next()
          }else{
            next({name: '404'})
          }
        }
      },
     
      
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: '*',
    name:'404',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page404.vue')
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
