var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{style:({ background: _vm.$vuetify.theme.themes.light.background })},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',[_c('v-app',{style:({ background: _vm.$vuetify.theme.themes.dark.background })},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"pt-5 pb-5",staticStyle:{"width":"100%"}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-card',[[_c('v-data-table',{ref:"datatable",staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.datos,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"ID_Ben","show-expand":"","search":_vm.search,"loading":_vm.loading},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('v-btn',{attrs:{"height":"100%","dark":"","color":"green darken-1"},on:{"click":_vm.excel}},[_c('v-icon',{staticClass:"mb-2",attrs:{"large":""}},[_vm._v(" fas fa-file-excel ")])],1)],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"0"},attrs:{"colspan":headers.length}},[_c('v-card',{staticStyle:{"width":"100vw"}},[_c('v-card-text',[_c('v-btn',{attrs:{"height":"100%","dark":"","color":"green darken-1"},on:{"click":function($event){return _vm.registrar(item)}}},[_vm._v(" Registrar Entrega ")])],1)],1)],1)]}},{key:"item.Orden",fn:function(props){return [_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){_vm.temp = props.item.Orden}}},'p',attrs,false),on),[_vm._v(" "+_vm._s(props.item.Orden)+" ")])]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v("Modificar Orden")]),_c('v-card-text',[_c('v-text-field',{staticStyle:{"margin-top":"1rem"},attrs:{"label":"Orden","rules":_vm.rules},model:{value:(_vm.temp),callback:function ($$v) {_vm.temp=$$v},expression:"temp"}})],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{staticStyle:{"color":"white"},attrs:{"color":"red accent-4"},on:{"click":function($event){dialog.value = false}}},[_vm._v("Cancelar")]),_c('v-btn',{staticStyle:{"color":"white"},attrs:{"color":"green lighten-2","disabled":_vm.temp == '' || isNaN(_vm.temp)},on:{"click":function($event){(dialog.value = false),
                                    _vm.actualizarOrden(props.item, _vm.temp),
                                    (props.item.Orden = _vm.temp)}}},[_vm._v("Guardar")])],1)],1)]}}],null,true)})]}}])})],_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"600"},model:{value:(_vm.dialogDetalles.value),callback:function ($$v) {_vm.$set(_vm.dialogDetalles, "value", $$v)},expression:"dialogDetalles.value"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v(_vm._s(_vm.dialogDetalles.title))]),_c('v-card-text',[_c('v-autocomplete',{staticStyle:{"margin-top":"1rem"},attrs:{"items":_vm.estados,"dense":"","filled":"","item-text":"valor","item-value":"clave","label":"Filled"},model:{value:(_vm.dialogDetalles.item),callback:function ($$v) {_vm.$set(_vm.dialogDetalles, "item", $$v)},expression:"dialogDetalles.item"}}),_c('v-container',{attrs:{"fluid":""}},[_c('v-textarea',{attrs:{"clearable":"","clear-icon":"mdi-close-circle","label":"Anotaciones"},model:{value:(_vm.anotaciones),callback:function ($$v) {_vm.anotaciones=$$v},expression:"anotaciones"}})],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialogDetalles.value = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){(_vm.dialogDetalles.value = false),
                            _vm.registrarEntrega(
                              _vm.dialogDetalles.itemCompleto,
                              _vm.dialogDetalles.item,
                              _vm.anotaciones
                            )}}},[_vm._v("Guardar")])],1)],1)],1)],2)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }