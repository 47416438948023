<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <v-layout row>
      <v-flex>
        <v-app :style="{ background: $vuetify.theme.themes.dark.background }" class="">
          <v-container fluid>
            <v-row class="pt-5 pb-5" style="width: 100%">
              <v-col cols="12" sm="12">
                <v-card style="padding: 1rem">
                  <h1>Agregar Nuevo Ciclo</h1>
                  <h1>El Ciclo Actual Es: {{ cicloActual }}</h1>
                  <v-row>
                    <v-col cols="12" sm="4">
                      <!-- <h1>Seleccione el ciclo a consultar</h1> -->
                      <v-autocomplete
                        v-model="ciclo"
                        :items="ciclos"
                        dense
                        background-color="white"
                        filled
                        :label="`Seleccione el Ciclo a Agregar`"
                        item-text="clave"
                        item-value="valor"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-dialog
                        ref="dialog"
                        v-model="modal"
                        :return-value.sync="fechaInicio"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fechaInicio"
                            label="Fecha De Inicio"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          @change="cambiarFecha"
                          locale="es"
                          :allowed-dates="allowedDates"
                          v-model="fechaInicio"
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="modal = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(fechaInicio)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <!-- <h1>Seleccione el tipo de entrega</h1> -->
                      <v-dialog
                        ref="dialog2"
                        v-model="modal2"
                        :return-value.sync="fechaFin"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fechaFin"
                            label="Fecha De Fin"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          locale="es"
                          :allowed-dates="allowedDates2"
                          v-model="fechaFin"
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="modal2 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog2.save(fechaFin)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <div class="d-flex justify-center">
                      <v-btn @click="agregar" rounded color="primary" dark>
                        Agregar
                      </v-btn>
                    </div>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-app>
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    cicloActual: null,
    ciclos: [],
    ciclo: 1,
    fechaInicio: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    fechaFin: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    modal: false,
    modal2: false,
  }),
  created() {
    this.$store.state.stateLoad = true;
    axios
      .get("/servicio_medico/ciclos", {
        headers: {
        },
      })
      .then((response) => {
        response.data.forEach((element) => {
          this.ciclos.push({
            clave: "Ciclo " + element.desparasitacion_ciclo,
            valor: element.desparasitacion_ciclo,
          });
        });
        
      });
    axios
      .get("/servicio_medico/cicloActual", {
        headers: {
        },
      })
      .then((response) => {
        this.cicloActual = response.data[0].cliclo_activo;
        this.$store.state.stateLoad = false;
      });
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  methods: {
    agregar() {
      this.$store.state.stateLoad = true;
      axios
        .post(
          "/servicio_medico/agregarCicloActivo",
          {
            ciclo: this.ciclo,
            fechaInicio: this.fechaInicio,
            fechaFin: this.fechaFin,
          },
          {
            headers: {
                },
          }
        )
        .then((response) => {
          console.log("ACTUALIZADO");
          this.$store.state.stateLoad = false;
        });
    },
    allowedDates(val) {
      return val >= new Date().toISOString().substr(0, 10);
    },
    allowedDates2(val) {
      return val > new Date(this.fechaInicio).toISOString().substr(0, 10);
    },
    cambiarFecha() {
      if (this.fechaInicio > this.fechaFin) {
        this.fechaFin = new Date(this.fechaInicio).toISOString().substr(0, 10);
      }
    },
  },
};
</script>
<style scoped>
h1 {
  font-size: larger !important;
}
.buscar {
  width: 200px;
  border-radius: 30px !important;
  background: #2196f3;
  margin-top: 10px;
  height: 2.2rem;
  color: white;
}
</style>
