<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <v-layout row>
      <v-flex>
        <v-app :style="{ background: $vuetify.theme.themes.dark.background }" class="">
          <v-container fluid>
            <v-row class="pt-5 pb-5" style="width: 100%">
              <v-col cols="12" sm="12">
                <v-card>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <!-- <h1>Seleccione el estado de entrega</h1> -->
                      <div>
                        <h1>Seleccione el ciclo a consultar</h1>
                      </div>
                      <v-select
                        v-model="ciclosSelected"
                        :items="ciclos"
                        label="Ciclos"
                        item-text="clave"
                        item-value="valor"
                        style="padding-left: 1rem"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <!-- <h1>Seleccione el estado de entrega</h1> -->
                      <div class="mt-1">
                        <h1>Seleccione la especie de su mascota</h1>
                      </div>

                      <v-select
                        v-model="selectedEspecie"
                        :items="especie"
                        item-text="clave"
                        item-value="valor"
                        label="Especie"
                        style="padding-left: 1rem"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <h1>El Ciclo Actual Es: {{ cicloActual }}</h1>

                  <div class="d-flex justify-center">
                    <button class="buscar" @click="cicloSeleccionado()">Buscar</button>
                  </div>
                  <template>
                    <v-data-table
                      :headers="headers"
                      :items="datos"
                      :single-expand="singleExpand"
                      :expanded.sync="expanded"
                      item-key="idBeneficiario"
                      show-expand
                      class="elevation-1"
                      :search="search"
                      @current-items="getFiltered"
                      ref="datatable"
                      :loading="loading"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-toolbar-title>
                            <v-btn
                              @click="excel"
                              height="100%"
                              dark
                              color="green darken-1"
                            >
                              <v-icon class="mb-2" large> fas fa-file-excel </v-icon>
                            </v-btn>
                          </v-toolbar-title>
                          <v-spacer></v-spacer>
                          <!-- <v-switch
                            v-model="singleExpand"
                            label="Single expand"
                            class="mt-2"
                          ></v-switch> -->
                          <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                          ></v-text-field>
                        </v-toolbar>
                      </template>
                      <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" style="padding: 0">
                          <v-card style="width: 100vw" v-for="i in item.extra">
                            <v-card-text
                              v-bind:class="{ colorModificar: i.funcion != 'default' }"
                              @click="eso(i.funcion, item)"
                              >{{ i.key }} {{ i.value }}
                            </v-card-text>
                          </v-card>
                          <v-card style="width: 100vw">
                            <v-card-text>
                              <v-btn
                                @click="subirUnico(item)"
                                height="100%"
                                dark
                                color="green darken-1"
                              >
                                Registrar
                              </v-btn>
                            </v-card-text>
                          </v-card>
                        </td>
                      </template>
                      <template v-slot:item.Raza2="props">
                        <v-dialog transition="dialog-bottom-transition" max-width="600">
                          <template v-slot:activator="{ on, attrs }">
                            <p
                              @click="temp = props.item.Raza2"
                              color="primary"
                              style="color: #2196f3"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ props.item.Raza2 }}
                            </p>
                          </template>
                          <template v-slot:default="dialog">
                            <v-card>
                              <v-toolbar color="primary" dark>Modificar Raza 2</v-toolbar>
                              <v-card-text>
                                <v-autocomplete
                                  v-model="temp"
                                  :items="razas"
                                  dense
                                  filled
                                  label="Filled"
                                  style="margin-top: 1rem"
                                ></v-autocomplete>
                              </v-card-text>
                              <v-card-actions class="justify-end">
                                <v-btn
                                  color="red accent-4"
                                  style="color: white"
                                  @click="dialog.value = false"
                                  >Cancelar</v-btn
                                >
                                <v-btn
                                  color="green lighten-2"
                                  style="color: white"
                                  @click="
                                    (dialog.value = false),
                                      guardarRaza(props.item, temp),
                                      (props.item.Raza2 = temp)
                                  "
                                  >Guardar</v-btn
                                >
                              </v-card-actions>
                            </v-card>
                          </template>
                        </v-dialog>
                      </template>
                      <template v-slot:item.Peso="props">
                        <v-dialog transition="dialog-bottom-transition" max-width="600">
                          <template v-slot:activator="{ on, attrs }">
                            <p
                              @click="temp = props.item.Peso"
                              color="primary"
                              v-bind="attrs"
                              style="color: #2196f3"
                              v-on="on"
                            >
                              {{ props.item.Peso }}
                            </p>
                          </template>
                          <template v-slot:default="dialog">
                            <v-card>
                              <v-toolbar color="primary" dark>Modificar Peso</v-toolbar>
                              <v-card-text>
                                <v-text-field
                                  v-model="temp"
                                  label="Peso"
                                  :rules="rules"
                                  style="margin-top: 1rem"
                                ></v-text-field>
                              </v-card-text>
                              <v-card-actions class="justify-end">
                                <v-btn
                                  color="red accent-4"
                                  style="color: white"
                                  @click="dialog.value = false"
                                  >Cancelar</v-btn
                                >
                                <v-btn
                                  color="green lighten-2"
                                  style="color: white"
                                  :disabled="temp == '' || isNaN(temp) || temp >= 300"
                                  @click="
                                    (dialog.value = false),
                                      guardarPeso(props.item, temp),
                                      (props.item.Peso = temp)
                                  "
                                  >Guardar</v-btn
                                >
                              </v-card-actions>
                            </v-card>
                          </template>
                        </v-dialog>
                      </template>
                      <template v-slot:item.Activos="props">
                        <v-dialog
                          persistent
                          transition="dialog-bottom-transition"
                          max-width="600"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <p
                              @click="buscarActivos(props.item.idTitular)"
                              color="primary"
                              v-bind="attrs"
                              style="color: #2196f3"
                              v-on="on"
                            >
                              {{ props.item.Activos }}
                            </p>
                          </template>
                          <template v-slot:default="dialog">
                            <v-card>
                              <v-toolbar color="primary" dark
                                >Beneficiarios Activos</v-toolbar
                              >
                              <v-container>
                                <v-row>
                                  <v-col cols="12" sm="6">
                                    <div class="d-flex justify-center">
                                      <p>Nombre</p>
                                    </div>
                                  </v-col>
                                  <v-col cols="12" sm="6">
                                    <div class="d-flex justify-center">
                                      <p>Ciclo</p>
                                    </div>
                                  </v-col>
                                </v-row>
                                <v-row v-for="item in beneficiariosActivos">
                                  <v-col cols="12" sm="6">
                                    <div class="d-flex justify-center">
                                      <p>{{ item.clave }}</p>
                                    </div>
                                  </v-col>
                                  <v-col cols="12" sm="6">
                                    <div class="d-flex justify-center">
                                      <v-dialog
                                        transition="dialog-bottom-transition"
                                        max-width="600"
                                        persistent
                                      >
                                        <template v-slot:activator="{ on, attrs }">
                                          <p
                                            color="primary"
                                            v-bind="attrs"
                                            style="color: #2196f3"
                                            v-on="on"
                                            @click="
                                              temp =
                                                item.valor == null
                                                  ? 'ciclo ' + 0
                                                  : 'ciclo ' + item.valor
                                            "
                                          >
                                            {{ item.valor == null ? 0 : item.valor }}
                                          </p>
                                        </template>
                                        <template v-slot:default="dialog">
                                          <v-card>
                                            <v-toolbar color="primary" dark
                                              >Cambiar Ciclo</v-toolbar
                                            >
                                            <v-card-text>
                                              <v-autocomplete
                                                v-model="temp"
                                                :items="ciclosTabla"
                                                dense
                                                filled
                                                label="Filled"
                                                style="margin-top: 1rem"
                                              ></v-autocomplete>
                                            </v-card-text>

                                            <v-card-actions class="justify-end">
                                              <v-btn
                                                color="red accent-4"
                                                style="color: white"
                                                @click="dialog.value = false"
                                                >Cerrar</v-btn
                                              >
                                              <v-btn
                                                color="green lighten-2"
                                                style="color: white"
                                                @click="
                                                  (dialog.value = false),
                                                    guardarCiclo2(
                                                      item.id_ben,
                                                      temp,
                                                      item.valor,
                                                      item.clave
                                                    )
                                                "
                                                >Guardar</v-btn
                                              >
                                            </v-card-actions>
                                          </v-card>
                                        </template>
                                      </v-dialog>
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-container>
                              <v-card-actions class="justify-end">
                                <v-btn
                                  color="red accent-4"
                                  style="color: white"
                                  @click="dialog.value = false"
                                  >Cerrar</v-btn
                                >
                              </v-card-actions>
                            </v-card>
                          </template>
                        </v-dialog>
                      </template>
                      <template v-slot:item.Tipo_Dosis="props">
                        <v-dialog transition="dialog-bottom-transition" max-width="600">
                          <template v-slot:activator="{ on, attrs }">
                            <p
                              @click="temp = dosisComplemento[props.item.Tipo_Dosis]"
                              color="primary"
                              v-bind="attrs"
                              style="color: #2196f3"
                              v-on="on"
                            >
                              {{ props.item.Tipo_Dosis }}
                            </p>
                          </template>
                          <template v-slot:default="dialog">
                            <v-card>
                              <v-toolbar color="primary" dark>Modificar Dosis</v-toolbar>
                              <v-card-text>
                                <v-autocomplete
                                  v-model="temp"
                                  :items="dosis"
                                  dense
                                  filled
                                  label="Filled"
                                  style="margin-top: 1rem"
                                ></v-autocomplete>
                              </v-card-text>
                              <v-card-actions class="justify-end">
                                <v-btn
                                  color="red accent-4"
                                  style="color: white"
                                  @click="dialog.value = false"
                                  >Cancelar</v-btn
                                >
                                <v-btn
                                  color="green lighten-2"
                                  style="color: white"
                                  @click="
                                    (dialog.value = false),
                                      (props.item.Tipo_Dosis = devolverTexto(temp)),
                                      guardarDosis(props.item, temp)
                                  "
                                  >Guardar</v-btn
                                >
                              </v-card-actions>
                            </v-card>
                          </template>
                        </v-dialog>
                      </template>
                    </v-data-table>
                    <v-dialog
                      v-model="dialogDireccion.value"
                      transition="dialog-bottom-transition"
                      max-width="600"
                    >
                      <v-card>
                        <v-toolbar color="primary" dark>{{
                          dialogDireccion.title
                        }}</v-toolbar>
                        <!--Debes colocar unas condiciones para que tomes las reglas de dirección en nulas si es direccion o de solo numeros si es de numero
                        Ademas debes colocar condiciones dadas por v-if para desplegar o no el cuadro que requieras-->
                        <v-card-text>
                          <v-text-field
                            v-model="dialogDireccion.item"
                            label="Dirección"
                            :rules="rulesDireccion"
                            style="margin-top: 1rem"
                          ></v-text-field>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                            color="red accent-4"
                            style="color: white"
                            @click="dialogDireccion.value = false"
                            >Cancelar</v-btn
                          >
                          <v-btn
                            color="green lighten-2"
                            style="color: white"
                            @click="
                              (dialogDireccion.value = false),
                                guardarDireccion(
                                  dialogDireccion.itemCompleto,
                                  dialogDireccion.item
                                )
                            "
                            >Guardar</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog
                      v-model="dialogCelularTitular.value"
                      transition="dialog-bottom-transition"
                      max-width="600"
                    >
                      <v-card>
                        <v-toolbar color="primary" dark>{{
                          dialogCelularTitular.title
                        }}</v-toolbar>
                        <!--Debes colocar unas condiciones para que tomes las reglas de dirección en nulas si es direccion o de solo numeros si es de numero
                        Ademas debes colocar condiciones dadas por v-if para desplegar o no el cuadro que requieras-->
                        <v-card-text>
                          <v-text-field
                            v-model="dialogCelularTitular.item"
                            label="Celular"
                            :rules="rulesCelular"
                            style="margin-top: 1rem"
                          ></v-text-field>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                            color="red accent-4"
                            style="color: white"
                            @click="dialogCelularTitular.value = false"
                            >Cancelar</v-btn
                          >
                          <v-btn
                            color="green lighten-2"
                            style="color: white"
                            @click="
                              (dialogCelularTitular.value = false),
                                guardarCelularTitular(
                                  dialogCelularTitular.itemCompleto,
                                  dialogCelularTitular.item
                                )
                            "
                            >Guardar</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog
                      v-model="dialogCelularBeneficiario.value"
                      transition="dialog-bottom-transition"
                      max-width="600"
                    >
                      <v-card>
                        <v-toolbar color="primary" dark>{{
                          dialogCelularBeneficiario.title
                        }}</v-toolbar>
                        <!--Debes colocar unas condiciones para que tomes las reglas de dirección en nulas si es direccion o de solo numeros si es de numero
                        Ademas debes colocar condiciones dadas por v-if para desplegar o no el cuadro que requieras-->
                        <v-card-text>
                          <v-text-field
                            v-model="dialogCelularBeneficiario.item"
                            label="Celular"
                            :rules="rulesCelular"
                            style="margin-top: 1rem"
                          ></v-text-field>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                            color="red accent-4"
                            style="color: white"
                            @click="dialogCelularBeneficiario.value = false"
                            >Cancelar</v-btn
                          >
                          <v-btn
                            color="green lighten-2"
                            style="color: white"
                            @click="
                              (dialogCelularBeneficiario.value = false),
                                guardarCelularBeneficiario(
                                  dialogCelularBeneficiario.itemCompleto,
                                  dialogCelularBeneficiario.item
                                )
                            "
                            >Guardar</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog
                      v-model="dialogCiclo.value"
                      transition="dialog-bottom-transition"
                      max-width="600"
                    >
                      <v-card>
                        <v-toolbar color="primary" dark>{{
                          dialogCiclo.title
                        }}</v-toolbar>
                        <!--Debes colocar unas condiciones para que tomes las reglas de dirección en nulas si es direccion o de solo numeros si es de numero
                        Ademas debes colocar condiciones dadas por v-if para desplegar o no el cuadro que requieras-->
                        <v-card-text>
                          <v-autocomplete
                            v-model="dialogCiclo.item"
                            :items="ciclosTabla"
                            dense
                            filled
                            label="Filled"
                            style="margin-top: 1rem"
                          ></v-autocomplete>
                        </v-card-text>

                        <v-card-actions class="justify-end">
                          <v-btn
                            color="red accent-4"
                            style="color: white"
                            @click="dialogCiclo.value = false"
                            >Cancelar</v-btn
                          >
                          <v-btn
                            color="green lighten-2"
                            style="color: white"
                            @click="
                              (dialogCiclo.value = false),
                                guardarCiclo(dialogCiclo.itemCompleto, dialogCiclo.item)
                            "
                            >Guardar</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <div class="d-flex justify-center">
                      <button class="buscar" @click="registrarDatos()">
                        Registrar Datos
                      </button>
                    </div>
                  </template>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-app>
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    selected: 8,
    ciclos: [],
    ciclosTabla: [],
    ciclosSelected: -1,
    cicloActual: null,
    especie: [
      { clave: "Todos", valor: 0 },
      { clave: "Canino", valor: 1 },
      { clave: "Felino", valor: 2 },
    ],
    beneficiariosActivos: [],
    razasAux: {},
    selectedEspecie: 0,
    expanded: [],
    singleExpand: false,
    headers: [
      /* {
        text: "Dessert (100g serving)",
        align: "start",
        sortable: false,
        value: "name",
      }, */
      { text: "Documento", value: "Documento" },
      { text: "Peso", value: "Peso" },
      { text: "Raza2", value: "Raza2" },
      { text: "Tipo Dosis", value: "Tipo_Dosis" },
      { text: "Cédula", value: "Cédula" },
      { text: "Activos", value: "Activos" },
      { text: "Activos Ciclo", value: "Activos_Ciclo" },
      {},
    ],
    datos: [],
    search: "",
    datosExcel: [],
    dialogDireccion: {
      value: false,
      title: "Cambiar Dirección",
      item: "",
      itemCompleto: null,
    },
    dialogCelularTitular: {
      value: false,
      title: "Cambiar Celular Titular",
      item: "",
      itemCompleto: null,
    },
    dialogCelularBeneficiario: {
      value: false,
      title: "Cambiar Celular Beneficiario",
      item: "",
      itemCompleto: null,
    },
    dialogCiclo: {
      value: false,
      title: "Cambiar Ciclo",
      item: "",
      itemCompleto: null,
    },
    value: null,
    items: ["foo", "bar", "fizz", "buzz"],
    temp: null,
    razas: [],
    razasClaveValor: {},
    loading: false,
    dosis: [],
    dosisClaveValor: {},
    dosisComplemento: {},
    rules: [
      (value) => !!value || "Requerido.",
      (value) => !isNaN(value) || "Solo números",
      (value) => (value && value < 300) || "¿Estas Seguro que pesa mas de 300 kilos?",
    ],
    rulesDireccion: [(value) => !!value || "Requerido."],
    rulesCelular: [
      (value) => !!value || "Requerido.",
      (value) => !isNaN(value) || "Solo números",
    ],
  }),
  created() {
    this.$store.state.stateLoad = true;
    axios
      .get("/servicio_medico/ciclos", {
        headers: {
        },
      })
      .then((response) => {
        this.ciclos.push({ clave: "Todos", valor: -1 });
        this.ciclos.push({ clave: "Ciclo 0", valor: 0 });
        response.data.forEach((element) => {
          this.ciclos.push({
            clave: "Ciclo " + element.desparasitacion_ciclo,
            valor: element.desparasitacion_ciclo,
          });
        });
      });
    axios
      .get("/servicio_medico/ciclosTabla", {
        headers: {
        },
      })
      .then((response) => {
        this.ciclosTabla.push("ciclo 0");

        response.data.forEach((element) => {
          this.ciclosTabla.push("ciclo " + element.id);
        });
      });
    axios
      .get("/servicio_medico/razas", {
        headers: {
        },
      })
      .then((response) => {
        response.data.forEach((element) => {
          this.razas.push(element.valor);
          this.razasClaveValor[element.valor] = element.clave;
          this.razasAux[element.valor] = element.aux;
        });
      });
    axios
      .get("/servicio_medico/dosis", {
        headers: {
        },
      })
      .then((response) => {
        var contador = 0;
        response.data.forEach((element) => {
          this.dosisClaveValor[element.valor] = element.clave;
          if (contador == 0) {
            var complemento = element.valor.split(" mililitros ")[0];

            this.dosisComplemento[complemento + " mililitros"] = element.valor;
            contador++;
          } else {
            var complemento = element.valor.split(" mililitro ")[0];

            this.dosisComplemento[complemento + " mililitro"] = element.valor;
          }
        });

        this.dosis = Object.keys(this.dosisClaveValor);
      });
    axios
      .get("/servicio_medico/cicloActual", {
        headers: {
        },
      })
      .then((response) => {
        this.cicloActual = response.data[0].cliclo_activo;
        this.$store.state.stateLoad = false;
      });
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  methods: {
    cicloSeleccionado: function () {
      this.Buscar();
    },
    Buscar: async function () {
      // $("#boxLoading").addClass("loading");
      this.loading = true;
      this.$store.state.stateLoad = true;
      await axios
        .get("/servicio_medico/datosCiclos", {
          params: {
            ciclo: this.ciclosSelected,
            selectedEspecie: this.selectedEspecie,
          },
          headers: {
            },
        })
        .then((response) => {
          this.datos = [];
          this.datosExcel = response.data;
          response.data.forEach((element) => {
            this.datos.push({
              Documento: element.Cedula2,
              Peso: element.Peso == null ? 0 : element.Peso,
              Raza2: element.Raza2 == null ? "sin registro" : element.Raza2,
              idRaza2: element["ID Raza2"] == null ? "sin registro" : element["ID Raza2"],
              idBeneficiario: element["ID Beneficiario"],
              idTitular: element["ID Titular"],
              idGrupo: element["ID Grupo"],
              Activos: element["cuantos"],
              Activos_Ciclo: element["cuantos_ciclo"],
              Tipo_Dosis:
                element["Tipo Dosis"] == null ? "sin registro" : element["Tipo Dosis"],
              Cédula: element.Cedula,
              extra: [
                {
                  key: "Dirección Mascota",
                  value:
                    element["Direccion Mascota"] == null
                      ? "sin registro"
                      : element["Direccion Mascota"],
                  funcion: 1,
                },
                {
                  key: "Nombre Mascota",
                  value:
                    element["Nombre Mascota"] == null
                      ? "sin registro"
                      : element["Nombre Mascota"],
                  funcion: "default",
                },
                {
                  key: "Celular Titular",
                  value:
                    element["Celular Titular"] == null
                      ? "sin registro"
                      : element["Celular Titular"],
                  funcion: 2,
                },
                {
                  key: "Celular Mascota",
                  value:
                    element["Celular Mascota"] == null
                      ? "sin registro"
                      : element["Celular Mascota"],
                  funcion: 3,
                },
                {
                  key: "Ciclo Desparasitación",
                  value: element["Ciclo"] == null ? 0 : element["Ciclo"],
                  funcion: 4,
                },
                {
                  key: "Raza",
                  value: element.Raza == null ? "sin registro" : element.Raza,
                  funcion: "default",
                },
                {
                  key: "Ciudad Mascota",
                  value:
                    element["Ciudad Mascota"] == null
                      ? "sin registro"
                      : element["Ciudad Mascota"],
                  funcion: "default",
                },
                {
                  key: "Dirección Titular",
                  value:
                    element["Direccion Titular"] == null
                      ? "sin registro"
                      : element["Direccion Titular"],
                  funcion: "default",
                },
                {
                  key: "Nombre Titular",
                  value:
                    element["Nombre Titular"] == null
                      ? "sin registro"
                      : element["Nombre Titular"],
                  funcion: "default",
                },
                {
                  key: "Telefono Titular",
                  value:
                    element["Telefono Titular"] == null
                      ? "sin registro"
                      : element["Telefono Titular"],
                  funcion: "default",
                },
                {
                  key: "Telefono Mascota",
                  value:
                    element["Telefono Mascota"] == null
                      ? "sin registro"
                      : element["Telefono Mascota"],
                  funcion: "default",
                },
                {
                  key: "Ciudad Titular",
                  value:
                    element["Ciudad Titular"] == null
                      ? "sin registro"
                      : element["Ciudad Titular"],
                  funcion: "default",
                },
                {
                  key: "Dirección Grupo",
                  value:
                    element["Direccion Grupo"] == null
                      ? "sin registro"
                      : element["Direccion Grupo"],
                  funcion: "default",
                },
                {
                  key: "Nombre Grupo",
                  value:
                    element["Nombre Grupo"] == null
                      ? "sin registro"
                      : element["Nombre Grupo"],
                  funcion: "default",
                },
                {
                  key: "Telefono Grupo",
                  value:
                    element["Telefono Grupo"] == null
                      ? "sin registro"
                      : element["Telefono Grupo"],
                  funcion: "default",
                },
                {
                  key: "Celular Grupo",
                  value:
                    element["Celular Grupo"] == null
                      ? "sin registro"
                      : element["Celular Grupo"],
                  funcion: "default",
                },
                {
                  key: "Ciudad Grupo",
                  value:
                    element["Ciudad Grupo"] == null
                      ? "sin registro"
                      : element["Ciudad Grupo"],
                  funcion: "default",
                },
                {
                  key: "Notas",
                  value: element["Notas"] == null ? "sin registro" : element["Notas"],
                  funcion: "default",
                },
              ],
            });
          });
          this.loading = false;
          this.$store.state.stateLoad = false;
        });

      // $("#boxLoading").removeClass("loading")
    },
    esto(algo) {
      this.$refs.datatable.dark = true;
    },
    eso(algo, item) {
      switch (algo) {
        case "default":
          break;
        case 1:
          this.dialogDireccion.item = item.extra[0].value;
          this.dialogDireccion.itemCompleto = item;
          this.dialogDireccion.value = true;

          break;
        case 2:
          this.dialogCelularTitular.item = item.extra[2].value;
          this.dialogCelularTitular.itemCompleto = item;
          this.dialogCelularTitular.value = true;
          break;
        case 3:
          this.dialogCelularBeneficiario.item = item.extra[3].value;
          this.dialogCelularBeneficiario.itemCompleto = item;
          this.dialogCelularBeneficiario.value = true;
          break;
        case 4:
          this.dialogCiclo.item = "ciclo " + item.extra[4].value;
          this.dialogCiclo.itemCompleto = item;
          this.dialogCiclo.value = true;
          break;

        default:
          break;
      }
    },
    guardarDireccion(itemCompleto, direccion) {
      this.datos.forEach((element) => {
        if (element.idBeneficiario == itemCompleto.idBeneficiario) {
          element.extra[0].value = direccion;
          this.$store.state.stateLoad = true;
          axios
            .post(
              "/servicio_medico/actualizarDireccion",
              {
                id: element.idBeneficiario,
                valor: direccion,
              },
              {
                headers: {
                        },
              }
            )
            .then((response) => {
              this.$store.state.stateLoad = false;
            });
          return;
        }
      });
    },
    guardarCelularTitular(itemCompleto, celular) {
      this.datos.forEach((element) => {
        if (element.idBeneficiario == itemCompleto.idBeneficiario) {
          element.extra[2].value = celular;
          this.$store.state.stateLoad = true;
          axios
            .post(
              "/servicio_medico/actualizarCelularTitular",
              {
                id: element.idTitular,
                valor: celular,
              },
              {
                headers: {
                        },
              }
            )
            .then((response) => {
              this.$store.state.stateLoad = false;
            });
          return;
        }
      });
    },
    guardarCelularBeneficiario(itemCompleto, celular) {
      this.datos.forEach((element) => {
        if (element.idBeneficiario == itemCompleto.idBeneficiario) {
          element.extra[3].value = celular;
          this.$store.state.stateLoad = true;
          axios
            .post(
              "/servicio_medico/actualizarCelularBeneficiario",
              {
                id: element.idBeneficiario,
                valor: celular,
              },
              {
                headers: {
                        },
              }
            )
            .then((response) => {
              this.$store.state.stateLoad = false;
            });
          return;
        }
      });
    },
    guardarCiclo(itemCompleto, ciclo) {
      this.datos.forEach((element) => {
        if (element.idBeneficiario == itemCompleto.idBeneficiario) {
          element.extra[4].value = ciclo.split("ciclo ")[1];
          this.$store.state.stateLoad = true;
          axios
            .post(
              "/servicio_medico/actualizarCiclo",
              {
                id: element.idBeneficiario,
                valor: ciclo.split("ciclo")[1],
              },
              {
                headers: {
                        },
              }
            )
            .then((response) => {
              this.$store.state.stateLoad = false;
            });
          return;
        }
      });
    },
    getFiltered(e) {},
    excel() {
      this.$store.state.stateLoad = true;
      axios({
        method: "post",
        url: "/excel",

        headers: {
        },

        data: {
          datos: this.datosExcel,
        },

        responseType: "blob",
      }).then((response) => {
        this.$store.state.stateLoad = false;
        // $("#boxLoading").removeClass("loading");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.xlsx");
        document.body.appendChild(link);
        link.click();
      });
    },
    open() {},
    guardarRaza(props, temp) {
      if (this.razasAux[temp]) {
        props.Tipo_Dosis = this.dosis[this.razasAux[temp] - 1];
      }
      if (this.razasClaveValor[temp]) {
        this.$store.state.stateLoad = true;
        axios
          .post(
            "/servicio_medico/actualizarRaza",
            {
              id: props.idBeneficiario,
              valor: this.razasClaveValor[temp],
              aux: this.razasAux[temp],
            },
            {
              headers: {
                    },
            }
          )
          .then((response) => {
            this.$store.state.stateLoad = false;
          });
      }
    },
    guardarPeso(props, temp) {
      this.$store.state.stateLoad = true;
      axios
        .post(
          "/servicio_medico/actualizarPeso",
          {
            id: props.idBeneficiario,
            valor: temp,
          },
          {
            headers: {
                },
          }
        )
        .then((response) => {
          this.$store.state.stateLoad = false;
        });
    },
    guardarDosis(props, temp) {
      this.$store.state.stateLoad = true;
      axios
        .post(
          "/servicio_medico/actualizarDosis",
          {
            id: props.idBeneficiario,
            valor: this.dosisClaveValor[temp],
          },
          {
            headers: {
                },
          }
        )
        .then((response) => {
          this.$store.state.stateLoad = false;
        });
    },
    devolverTexto(temp) {
      return temp.includes("0.5 mililitros")
        ? temp.split(" mililitros ")[0] + " mililitros"
        : temp.split(" mililitro ")[0] + " mililitro";
    },
    registrarDatos() {
      this.loading = true;
      var Datos = [];
      this.datos.forEach((element) => {
        Datos.push({
          "ID Beneficiario": element.idBeneficiario,
          "ID Titular": element.idTitular,
          "ID Grupo": element.idGrupo,
          ciclo: element.extra[4].value,
        });
      });
      this.$store.state.stateLoad = true;
      axios
        .post(
          "/servicio_medico/ingresarDesparasitacion",
          {
            Datos: Datos,
          },
          {
            headers: {
                },
          }
        )
        .then((response) => {
          this.loading = false;
          this.$store.state.stateLoad = false;
        });
    },
    subirUnico(item) {
      var Datos = [
        {
          "ID Beneficiario": item.idBeneficiario,
          "ID Titular": item.idTitular,
          "ID Grupo": item.idGrupo,
          ciclo: item.extra[4].value,
        },
      ];
      this.$store.state.stateLoad = true;
      axios
        .post(
          "/servicio_medico/ingresarDesparasitacion",
          {
            Datos: Datos,
          },
          {
            headers: {
                },
          }
        )
        .then((response) => {
          this.$store.state.stateLoad = false;
        });
    },
    async buscarActivos(id) {
      this.beneficiariosActivos = [];
      this.$store.state.stateLoad = true;
      await axios
        .get("/servicio_medico/buscarActivos", {
          params: {
            id: id,
          },
          headers: {
            },
        })
        .then((response) => {
          this.beneficiariosActivos = response.data;
          this.$store.state.stateLoad = false;
        });
    },
    guardarCiclo2(item, ciclo, valor, texto) {
      this.beneficiariosActivos.forEach((element) => {
        if (element.clave == texto) {
          element.valor = ciclo.split("ciclo ")[1];
          this.$store.state.stateLoad = true;
          axios
            .post(
              "/servicio_medico/actualizarCiclo",
              {
                id: item,
                valor: element.valor,
              },
              {
                headers: {
                        },
              }
            )
            .then((response) => {
              this.$store.state.stateLoad = false;
              return;
            });
        }
      });
    },
  },
};
</script>
<style>
li {
  white-space: initial;
}

select {
  border: 0px !important;
  border-bottom: black solid 1px !important;

  height: 2.5em;
  padding-left: 10px;
  font-size: 1.5rem;
  width: 100%;
}

.inputs {
  width: 31.3333333%;
  margin: 1%;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
}

.inputs > div {
  height: 100%;
  display: flex;
  align-items: center;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control:before {
  background-color: #6c757d !important;
}

table.dataTable thead {
  background: linear-gradient(to right, #0575e6, #00f260);
  color: white;
}

@media (max-width: 600px) {
  .alinear {
    flex-flow: column !important;
  }

  .inputs {
    width: 100%;
  }
}

.row {
  justify-content: center !important;
}

h1 {
  font-size: 1.5rem !important;
  text-align: center;
}

.buscar {
  width: 200px;
  border-radius: 30px !important;
  background: #2196f3;
  margin-top: 10px;
  height: 2.2rem;
  color: white;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url("https://media.giphy.com/media/xTk9ZvMnbIiIew7IpW/source.gif") 50% 50%
    no-repeat rgb(249, 249, 249);
  opacity: 0.8;
}
tr:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
.colorModificar {
  color: #2196f3 !important;
  cursor: pointer;
}
</style>
