<template>
  <v-app v-if="validar" :style="{ background: $vuetify.theme.themes.light.background }">
    <v-layout row>
      <v-flex>
        <v-app :style="{ background: $vuetify.theme.themes.dark.background }" class="">
          <v-container>
            <v-row class="pt-5 pb-5" style="width: 100%">
              <v-col cols="12" sm="12">
                <v-card>
                  <table-users-info :headers="headers" :datos="datos"></table-users-info>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-app>
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    headers: [
      { text: "ID", value: "id" },
      { text: "Nombre", value: "nombre" },
      { text: "Correo", value: "correo" },
      { text: "Datos", value: "datos" },
      { text: "Editar", value: "editar" },
      { text: "Borrar", value: "borrar" },
    ],
    datos: [],
    validar: false,
  }),
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  beforeCreate() {
  },
  created() {
    this.validar = JSON.parse(localStorage.getItem("user"))[0]["full-access"] == "yes";
    if(this.validar){
      axios
      .get("/consultar-usuarios", {
        headers: {
        },
      })
      .then((response) => {
        var permisos = response.data[1].map((value) => value.name);

        response.data[0].forEach((element) => {
          var permisosUsuario = [];
          element.permisos.forEach((permiso) => {
            permisosUsuario.push(permiso[1]);
          });

          this.datos.push({
            id: element.id,
            nombre: element.name,
            correo: element.email,
            items: permisos,
            value: permisosUsuario,
          });
        });
      });
    }
    
  },
  methods: {},
};
</script>
