<template>
  <v-container>
    <v-card>
      <v-toolbar dark color="grey darken-3" height="40">
        <v-spacer></v-spacer>
        <v-toolbar-title>Agregar Consulta</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text class="mt-4">
        <v-row>
          <v-col md="2">
            <v-text-field
              v-model="contrato"
              label="Contrato"
              dense
              hide-details
              outlined
            ></v-text-field>
          </v-col>
          <v-col md="2">
            <v-text-field
              v-model="beneficiario"
              label="Beneficiario"
              dense
              hide-details
              outlined
            ></v-text-field>
          </v-col>
          <v-col md="2">
            <v-text-field
              v-model="documento"
              label="Documento"
              dense
              hide-details
              outlined
            ></v-text-field
          ></v-col>
          <v-col md="2">
            <v-text-field
              v-model="telefono"
              label="Telefono"
              dense
              hide-details
              outlined
            ></v-text-field
          ></v-col>
          <v-col md="2">
            <v-text-field
              v-model="direccion"
              label="Dirección"
              dense
              hide-details
              outlined
            ></v-text-field>
          </v-col>
          <v-col md="2">
            <v-btn @click="buscar" class="ml-2 mt-1" color="primary">Buscar</v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card>
        <!-- BARRA DE BUSQUEDA DEL DATATABLE MODAL AGREGAR CONSULTA -->

        <v-data-table
          :headers="headers_datatable_agregar_consulta"
          :items="data_datatable_agregar_consulta"
          sort-by="calories"
          class="elevation-1"
          :search="search"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <!-- <v-switch
                            v-model="singleExpand"
                            label="Single expand"
                            class="mt-2"
                          ></v-switch> -->
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-toolbar>
          </template>

          <!-- <template v-slot:[`item.modal`]="{ asd }">              

          <v-list>
              <v-list-group
                  :value="false">
                  <template v-slot:activator>
                  
                  </template>

                    <v-list-item
                        v-for="(item, i) in items"
                      :key="i"
                      >
                      <v-list-item-content>
                          <v-btn small text @click="abrirModal(asd)" >{{item.title}}</v-btn>
                      </v-list-item-content>
                    </v-list-item>
              </v-list-group>
              </v-list>



          </template> -->

          <template v-slot:item.modal="props">
            <div class="text-center">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                </template>
                <v-list>
                  <v-list-item v-for="(item, index) in items" :key="index">
                    <v-list-item-title
                      class="personalizar"
                      @click="abrirModal(item.opcion, props.item)"
                      >{{ item.title }}</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
        </v-data-table>
        <v-dialog v-model="modal_agregar_consulta" max-width="1200px">
          <v-card>
            <v-toolbar dark color="grey darken-3">
              <v-spacer></v-spacer>
              <v-toolbar-title>Beneficiarios AP</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>

            <v-card-text>
              <v-row class="mt-4">
                <v-col>
                  <v-text-field label="Nombre" dense hide-details outlined></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Documento"
                    dense
                    hide-details
                    outlined
                  ></v-text-field
                ></v-col>
                <v-col>
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        label="Fecha de Nacimiento"
                        prepend-icon="mdi-calendar"
                        readonly
                        dense
                        outlined
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date" @input="menu2 = false"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-select
                    label="Genero"
                    :items="genero"
                    dense
                    outlined
                    hide-details
                    v-model="genero_seleccionado"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-select
                    label="Eps"
                    :items="eps"
                    dense
                    outlined
                    hide-details
                    v-model="eps_seleccionada"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-textarea
                    label="Antecedentes"
                    height="10"
                    dense
                    hide-details
                    outlined
                    rows="1"
                    row-height="15"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="guardar"> Guardar</v-btn>
              </v-row>

              <v-data-table
                class="mt-6"
                :headers="headers_datatable_beneficiarios_ap"
                :items="data_datatable_beneficiarios_ap"
                dense
              >
              </v-data-table>
            </v-card-text>

            <v-card-actions class="pa-0"> </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "AgregarConsulta",

  data: () => ({
    search: "",
    genero: ["Masculino", "Femenino"],
    genero_seleccionado: "",
    eps: ["sanitas", "cafesalud"],
    eps_seleccionada: "",
    modal_agregar_consulta: false,
    headers_datatable_agregar_consulta: [
      { text: "", value: "modal" },
      { text: "Contrato", value: "contrato" },
      { text: "Prioridad", value: "prioridades_calculadas" },
      { text: "Beneficiario", value: "nombre" },
      { text: "Edad", value: "edad" },
      { text: "Documento", value: "bendoc" },
      { text: "Telefono", value: "telefono" },
      { text: "Dirección", value: "direccion" },
      { text: "Estado", value: "estado" },
      { text: "Ingreso", value: "fechaIngreso" },
      { text: "Baja/Act", value: "baja" },
      { text: "Retiro OP", value: "fechaRetiro" },
    ],
    data_datatable_agregar_consulta: [],
    items: [
      {
        title: "Usuarios AP",
        opcion: 1,
      },
      {
        title: "Registrar",
        opcion: 2,
      },
    ],
    headers_datatable_beneficiarios_ap: [
      { text: "Nombre", value: "bennom" },
      { text: "Documento", value: "bendoc" },
      { text: "Fecha de Nacimiento", value: "bennac" },
      { text: "Sexo", value: "sexo" },
      { text: "Eps", value: "beneps" },
      { text: "Matricula", value: "matricula" },
      { text: "Antecedentes", value: "bentxtAP" },
      { text: "Enviar", value: "enviar" },
      { text: "Guardar", value: "guardar" },
    ],
    data_datatable_beneficiarios_ap: [],

    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu2: false,
    persona: [{ nombre: "mitchel" }, { nombre: "Jimena" }],
    beneficiario: "",
    contrato: "",
    documento: "",
    telefono: "",
    direccion: "",
    prioridades: [],
  }),
  methods: {
    abrirModal(item, props) {
      switch (item) {
        case 1:
          var indice = this.persona.indexOf(item);
          this.modal_agregar_consulta = true;
          console.log("modal:", this.modal_agregar_consulta);
          console.log("indice", indice);
          console.log(props);
          Axios.get("/datosAp", {
            params: { matricula: props.bendoc },
          }).then((response) => {
            console.log(response);
            this.data_datatable_beneficiarios_ap = response.data;
          });
          break;
        case 2:
          var codigo = props.estadoCod;

          if (codigo != 0 && codigo != 15 && props.tiempoDisponible < 0) {
            Swal.fire("Error, no puede registrar servicio!", "", "error");
          } else {
            Axios.post("/nuevoservicio", {
              beneficiario: props.id_ben,
            }).then(() => {
              Swal.fire("registrado!", "", "success");
            });
          }
          console.log(props);

          break;

        default:
          break;
      }
    },
    cerrarModal() {
      this.modal_agregar_consulta = false;
      console.log("Cerrando modal");
    },
    guardar() {
      this.modal_agregar_consulta = false;
    },
    buscar() {
      Axios.get("/buscarUsuariosAgregarServicios", {
        params: {
          nombre: this.beneficiario,
          contrato: this.contrato,
          documento: this.documento,
          telefono: this.telefono,
          direccion: this.direccion,
        },
        headers: {},
      }).then((response) => {
        this.data_datatable_agregar_consulta = response.data;
        this.data_datatable_agregar_consulta.forEach((element) => {
          let temp = element.prioridades_calculadas;
          let string = typeof temp;
          let COR = false;
          if (string == "string") {
            COR = temp.includes("COR");
            console.log(COR);
          }

          if (COR) {
            temp = Number(temp.split(" ")[1]);
            console.log(temp);
          }
          if (
            temp >= this.prioridades[0].rango_inicial &&
            temp < this.prioridades[0].rango_final
          ) {
            element.prioridades_calculadas = this.prioridades[0].prioridad;
          } else if (
            temp >= this.prioridades[1].rango_inicial &&
            temp < this.prioridades[1].rango_final
          ) {
            element.prioridades_calculadas = this.prioridades[1].prioridad;
          } else if (
            temp >= this.prioridades[2].rango_inicial &&
            temp < this.prioridades[2].rango_final
          ) {
            element.prioridades_calculadas = this.prioridades[2].prioridad;
          } else if (
            temp >= this.prioridades[3].rango_inicial &&
            temp < this.prioridades[3].rango_final
          ) {
            element.prioridades_calculadas = this.prioridades[3].prioridad;
          } else if (
            temp >= this.prioridades[4].rango_inicial &&
            temp < this.prioridades[4].rango_final
          ) {
            element.prioridades_calculadas = this.prioridades[4].prioridad;
          } else if (temp >= this.prioridades[5].rango_inicial) {
            element.prioridades_calculadas = this.prioridades[5].prioridad;
          }
          if (COR) {
            element.prioridades_calculadas = "COR " + element.prioridades_calculadas;
          }
        });
      });
    },
  },
  async created() {
    await Axios.get("/prioridades", {}).then((response) => {
      this.prioridades = response.data;
    });
  },
};
</script>

<style scoped>
.personalizar:hover {
  background: rgb(235, 233, 233) !important;
  cursor: pointer;
}

.personalizar {
  padding: 8px 13px 10px 10px;
}
</style>
