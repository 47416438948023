<template>
  <v-container>
      <v-card>
          <v-toolbar dark color="grey darken-3" height="40">
              <v-spacer></v-spacer>
                <v-toolbar-title>Prioridad por Sintoma</v-toolbar-title>
              <v-spacer></v-spacer>
          </v-toolbar>
        
            <v-card-text>
                <v-select
                  :items="prioridad_sintoma" 
                  v-model="prioridad_seleccionada"
                  dense
                  outlined
                  hide-details   
                  label="Prioridad Por Sintoma"
                >
                    
                </v-select>
            </v-card-text>

      </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'SignosSintomas',

    data: () => ({
        prioridad_sintoma: ['1','2','3'],
        prioridad_seleccionada: '',
    }),
  }
</script>
