<template>
  <v-container>
   
          <v-toolbar dark color="grey darken-3" height="40">
              <v-spacer></v-spacer>
                <v-toolbar-title>VER EQUIPO</v-toolbar-title>
              <v-spacer></v-spacer>
          </v-toolbar>
        
            <template>
            <v-card>
                <v-card-title>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
                </v-card-title>
                <v-data-table
                :headers="headers"
                :items="data"
                :search="search"
                ></v-data-table>
            </v-card>
            </template>


  </v-container>
</template>

<script>
  export default {
    name: 'SignosSintomas',

    data: () => ({
        search: '',
        headers: [
            { text: 'VEHNOM', value: 'vehnom' },
            { text: 'VEHCOD', value: 'vehcod'},
            { text: 'MEDICO', value: 'medico'},
            { text: 'AUXILIAR', value: 'auxiliar'},
            { text: 'CONDUCTOR', value: 'conductor'},
            { text: 'ID_EQUIPO', value: 'id_equipo'},
            { text: 'ULT ASIGNACIÓN', value: 'ult_asignacion'},
            { text: 't', value: 't'},
            { text: 'CEL MOVIL', value: 'cel_movil'}
        ],
        data: [{
            vehnom: 'asd', 
            vehcod: 'asda', 
            medico: 'carlos',
            auxiliar: 'aux',
            conductor: 'condc',
            id_equipo: 'edrasd',
            ult_asignacion: 'ult',
            t: 'asd',
            cel_movil: '13123123232'
        }]
    }),
  }
</script>
