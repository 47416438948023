<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <v-layout row>
      <v-flex>
        <v-app :style="{ background: $vuetify.theme.themes.dark.background }" class="">
          <v-container fluid>
            <v-row class="pt-5 pb-5" style="width: 100%">
              <v-col cols="12" sm="12">
                <v-card style="padding: 1rem">
                  <v-row>
                    <v-col cols="12" sm="3">
                      <v-dialog
                        ref="dialog"
                        v-model="modal"
                        :return-value.sync="fechaInicio"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fechaInicio"
                            label="Fecha De Inicio"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          @change="cambiarFecha"
                          locale="es"
                          v-model="fechaInicio"
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="modal = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(fechaInicio)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <!-- <h1>Seleccione el tipo de entrega</h1> -->
                      <v-dialog
                        ref="dialog2"
                        v-model="modal2"
                        :return-value.sync="fechaFin"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fechaFin"
                            label="Fecha De Fin"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker locale="es" v-model="fechaFin" scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="modal2 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog2.save(fechaFin)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-autocomplete
                        v-model="tipoContrato"
                        :items="tiposContrato"
                        dense
                        label="Seleccione el tipo de contrato"
                        style="margin-top: 1.4rem"
                        item-text="valor"
                        item-value="clave"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-autocomplete
                        v-model="ciclo"
                        :items="ciclos"
                        dense
                        label="Seleccione el Ciclo de Facturación"
                        style="margin-top: 1.4rem"
                        item-text="valor"
                        item-value="clave"
                      ></v-autocomplete>
                    </v-col>
                    <div class="d-flex justify-center">
                      <v-btn @click="buscar" rounded color="primary" dark> Buscar </v-btn>
                    </div>
                  </v-row>
                  <template>
                    <v-data-table
                      :headers="headers"
                      :items="datos"
                      item-key="ID_Desparasitacion"
                      class="elevation-1"
                      :search="search"
                      ref="datatable"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-toolbar-title>
                            <v-btn
                              @click="excel"
                              height="100%"
                              dark
                              color="green darken-1"
                            >
                              <v-icon class="mb-2" large> fas fa-file-excel </v-icon>
                            </v-btn>
                          </v-toolbar-title>
                          <v-spacer></v-spacer>
                          <!-- <v-switch
                            v-model="singleExpand"
                            label="Single expand"
                            class="mt-2"
                          ></v-switch> -->
                          <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Buscar"
                            single-line
                            hide-details
                          ></v-text-field>
                        </v-toolbar>
                      </template>
                      <template v-slot:item.TotalFacturasGeneradas="props">
                        <v-dialog transition="dialog-bottom-transition">
                          <template v-slot:activator="{ on, attrs }">
                            <p
                              @click="temp = props.item.TotalFacturasGeneradas"
                              color="primary"
                              style="color: #2196f3; margin-bottom: 0"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ props.item.TotalFacturasGeneradas }}
                            </p>
                          </template>
                          <template v-slot:default="dialog">
                            <v-card>
                              <v-toolbar color="primary" dark
                                >Datos Facturas Generadas</v-toolbar
                              >
                              <v-card-text>
                                <simple-datatable
                                  style="margin-top: 1rem"
                                  :headers="headersInternos"
                                  :datos="datosTotalGeneradas"
                                >
                                </simple-datatable>
                              </v-card-text>
                              <v-card-actions class="justify-end">
                                <v-btn
                                  color="red accent-4"
                                  style="color: white"
                                  @click="dialog.value = false"
                                  >Cerrar</v-btn
                                >
                              </v-card-actions>
                            </v-card>
                          </template>
                        </v-dialog>
                      </template>
                      <template v-slot:item.ValorFacturasGeneradas="props">
                        <p color="primary" style="margin-bottom: 0">
                          {{ props.item.ValorFacturasGeneradas | numbers }}
                        </p>
                      </template>
                      <template v-slot:item.ValorFacturasPendientes="props">
                        <p color="primary" style="margin-bottom: 0">
                          {{ props.item.ValorFacturasPendientes | numbers }}
                        </p>
                      </template>
                      <template v-slot:item.ValorFacturasRecaudado="props">
                        <p color="primary" style="margin-bottom: 0">
                          {{ props.item.ValorFacturasRecaudado | numbers }}
                        </p>
                      </template>
                      <template v-slot:item.ValorFacturasDoblementeGeneradas="props">
                        <p color="primary" style="margin-bottom: 0">
                          {{ props.item.ValorFacturasDoblementeGeneradas | numbers }}
                        </p>
                      </template>
                      <template v-slot:item.ValorFacturasDoblementePagadas="props">
                        <p color="primary" style="margin-bottom: 0">
                          {{ props.item.ValorFacturasDoblementePagadas | numbers }}
                        </p>
                      </template>
                      <template v-slot:item.ValorFacturasGestionadas="props">
                        <p color="primary" style="margin-bottom: 0">
                          {{ props.item.ValorFacturasGestionadas | numbers }}
                        </p>
                      </template>
                      <template v-slot:item.ValorFacturasGestionadasSinPagar="props">
                        <p color="primary" style="margin-bottom: 0">
                          {{ props.item.ValorFacturasGestionadasSinPagar | numbers }}
                        </p>
                      </template>
                      <template v-slot:item.ValorFacturasGestionadasPagadas="props">
                        <p color="primary" style="margin-bottom: 0">
                          {{ props.item.ValorFacturasGestionadasPagadas | numbers }}
                        </p>
                      </template>
                      <template v-slot:item.ValorFacturasNoGestionadas="props">
                        <p color="primary" style="margin-bottom: 0">
                          {{ props.item.ValorFacturasNoGestionadas | numbers }}
                        </p>
                      </template>
                      <template v-slot:item.ValorFacturasNoGestionadasSinPagar="props">
                        <p color="primary" style="margin-bottom: 0">
                          {{ props.item.ValorFacturasNoGestionadasSinPagar | numbers }}
                        </p>
                      </template>
                      <template v-slot:item.ValorFacturasNoGestionadasPagadas="props">
                        <p color="primary" style="margin-bottom: 0">
                          {{ props.item.ValorFacturasNoGestionadasPagadas | numbers }}
                        </p>
                      </template>
                      <template v-slot:item.TotalFacturasPendientes="props">
                        <v-dialog transition="dialog-bottom-transition">
                          <template v-slot:activator="{ on, attrs }">
                            <p
                              @click="temp = props.item.TotalFacturasPendientes"
                              color="primary"
                              style="color: #2196f3; margin-bottom: 0"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ props.item.TotalFacturasPendientes }}
                            </p>
                          </template>
                          <template v-slot:default="dialog">
                            <v-card>
                              <v-toolbar color="primary" dark
                                >Datos Facturas Generadas Pendientes</v-toolbar
                              >
                              <v-card-text>
                                <simple-datatable
                                  style="margin-top: 1rem"
                                  :headers="headersInternos"
                                  :datos="datosPendientesPorPagar"
                                >
                                </simple-datatable>
                              </v-card-text>
                              <v-card-actions class="justify-end">
                                <v-btn
                                  color="red accent-4"
                                  style="color: white"
                                  @click="dialog.value = false"
                                  >Cerrar</v-btn
                                >
                              </v-card-actions>
                            </v-card>
                          </template>
                        </v-dialog>
                      </template>
                      <template v-slot:item.TotalFacturasRecaudado="props">
                        <v-dialog transition="dialog-bottom-transition">
                          <template v-slot:activator="{ on, attrs }">
                            <p
                              @click="temp = props.item.TotalFacturasRecaudado"
                              color="primary"
                              style="color: #2196f3; margin-bottom: 0"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ props.item.TotalFacturasRecaudado }}
                            </p>
                          </template>
                          <template v-slot:default="dialog">
                            <v-card>
                              <v-toolbar color="primary" dark
                                >Datos Facturas Generadas Recaudado</v-toolbar
                              >
                              <v-card-text>
                                <simple-datatable
                                  style="margin-top: 1rem"
                                  :headers="headersInternos"
                                  :datos="datosFacturasConRecado"
                                >
                                </simple-datatable>
                              </v-card-text>
                              <v-card-actions class="justify-end">
                                <v-btn
                                  color="red accent-4"
                                  style="color: white"
                                  @click="dialog.value = false"
                                  >Cerrar</v-btn
                                >
                              </v-card-actions>
                            </v-card>
                          </template>
                        </v-dialog>
                      </template>
                      <template v-slot:item.TotalFacturasDoblementeGeneradas="props">
                        <v-dialog transition="dialog-bottom-transition">
                          <template v-slot:activator="{ on, attrs }">
                            <p
                              @click="temp = props.item.TotalFacturasDoblementeGeneradas"
                              color="primary"
                              style="color: #2196f3; margin-bottom: 0"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ props.item.TotalFacturasDoblementeGeneradas }}
                            </p>
                          </template>
                          <template v-slot:default="dialog">
                            <v-card>
                              <v-toolbar color="primary" dark
                                >Datos Facturas Doblemente Generadas</v-toolbar
                              >
                              <v-card-text>
                                <simple-datatable
                                  style="margin-top: 1rem"
                                  :headers="headersInternos"
                                  :datos="datosFacturasDobles"
                                >
                                </simple-datatable>
                              </v-card-text>
                              <v-card-actions class="justify-end">
                                <v-btn
                                  color="red accent-4"
                                  style="color: white"
                                  @click="dialog.value = false"
                                  >Cerrar</v-btn
                                >
                              </v-card-actions>
                            </v-card>
                          </template>
                        </v-dialog>
                      </template>
                      <template v-slot:item.TotalFacturasDoblementePagadas="props">
                        <v-dialog transition="dialog-bottom-transition">
                          <template v-slot:activator="{ on, attrs }">
                            <p
                              @click="temp = props.item.TotalFacturasDoblementePagadas"
                              color="primary"
                              style="color: #2196f3; margin-bottom: 0"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ props.item.TotalFacturasDoblementePagadas }}
                            </p>
                          </template>
                          <template v-slot:default="dialog">
                            <v-card>
                              <v-toolbar color="primary" dark
                                >Datos Facturas Generadas doblemente pagadas</v-toolbar
                              >
                              <v-card-text>
                                <simple-datatable
                                  style="margin-top: 1rem"
                                  :headers="headersInternos"
                                  :datos="datosFacturasDoblesPagadas"
                                >
                                </simple-datatable>
                              </v-card-text>
                              <v-card-actions class="justify-end">
                                <v-btn
                                  color="red accent-4"
                                  style="color: white"
                                  @click="dialog.value = false"
                                  >Cerrar</v-btn
                                >
                              </v-card-actions>
                            </v-card>
                          </template>
                        </v-dialog>
                      </template>
                      <template v-slot:item.TotalFacturasGestionadas="props">
                        <v-dialog transition="dialog-bottom-transition">
                          <template v-slot:activator="{ on, attrs }">
                            <p
                              @click="temp = props.item.TotalFacturasGestionadas"
                              color="primary"
                              style="color: #2196f3; margin-bottom: 0"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ props.item.TotalFacturasGestionadas }}
                            </p>
                          </template>
                          <template v-slot:default="dialog">
                            <v-card>
                              <v-toolbar color="primary" dark
                                >Datos Facturas Generadas Gestionadas</v-toolbar
                              >
                              <v-card-text>
                                <simple-datatable
                                  style="margin-top: 1rem"
                                  :headers="headersInternos2"
                                  :datos="datosFacturasGestionadas"
                                >
                                </simple-datatable>
                              </v-card-text>
                              <v-card-actions class="justify-end">
                                <v-btn
                                  color="red accent-4"
                                  style="color: white"
                                  @click="dialog.value = false"
                                  >Cerrar</v-btn
                                >
                              </v-card-actions>
                            </v-card>
                          </template>
                        </v-dialog>
                      </template>

                      <template v-slot:item.TotalFacturasGestionadasSinPagar="props">
                        <v-dialog transition="dialog-bottom-transition">
                          <template v-slot:activator="{ on, attrs }">
                            <p
                              @click="temp = props.item.TotalFacturasGestionadasSinPagar"
                              color="primary"
                              style="color: #2196f3; margin-bottom: 0"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ props.item.TotalFacturasGestionadasSinPagar }}
                            </p>
                          </template>
                          <template v-slot:default="dialog">
                            <v-card>
                              <v-toolbar color="primary" dark
                                >Datos Facturas Generadas Gestionadas sin pagar</v-toolbar
                              >
                              <v-card-text>
                                <simple-datatable
                                  style="margin-top: 1rem"
                                  :headers="headersInternos2"
                                  :datos="datosFacturasGestionadasSinPagar"
                                >
                                </simple-datatable>
                              </v-card-text>
                              <v-card-actions class="justify-end">
                                <v-btn
                                  color="red accent-4"
                                  style="color: white"
                                  @click="dialog.value = false"
                                  >Cerrar</v-btn
                                >
                              </v-card-actions>
                            </v-card>
                          </template>
                        </v-dialog>
                      </template>
                      <template v-slot:item.TotalFacturasGestionadasPagadas="props">
                        <v-dialog transition="dialog-bottom-transition">
                          <template v-slot:activator="{ on, attrs }">
                            <p
                              @click="temp = props.item.TotalFacturasGestionadasPagadas"
                              color="primary"
                              style="color: #2196f3; margin-bottom: 0"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ props.item.TotalFacturasGestionadasPagadas }}
                            </p>
                          </template>
                          <template v-slot:default="dialog">
                            <v-card>
                              <v-toolbar color="primary" dark
                                >Datos Facturas Generadas Gestionadas pagadas</v-toolbar
                              >
                              <v-card-text>
                                <simple-datatable
                                  style="margin-top: 1rem"
                                  :headers="headersInternos2"
                                  :datos="datosFacturasGestionadasPagadas"
                                >
                                </simple-datatable>
                              </v-card-text>
                              <v-card-actions class="justify-end">
                                <v-btn
                                  color="red accent-4"
                                  style="color: white"
                                  @click="dialog.value = false"
                                  >Cerrar</v-btn
                                >
                              </v-card-actions>
                            </v-card>
                          </template>
                        </v-dialog>
                      </template>
                      <template v-slot:item.TotalFacturasNoGestionadas="props">
                        <v-dialog transition="dialog-bottom-transition">
                          <template v-slot:activator="{ on, attrs }">
                            <p
                              @click="temp = props.item.TotalFacturasNoGestionadas"
                              color="primary"
                              style="color: #2196f3; margin-bottom: 0"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ props.item.TotalFacturasNoGestionadas }}
                            </p>
                          </template>
                          <template v-slot:default="dialog">
                            <v-card>
                              <v-toolbar color="primary" dark
                                >Datos Facturas Generadas no gestionadas
                              </v-toolbar>
                              <v-card-text>
                                <simple-datatable
                                  style="margin-top: 1rem"
                                  :headers="headersInternos2"
                                  :datos="datosFacturasSinGestionar"
                                >
                                </simple-datatable>
                              </v-card-text>
                              <v-card-actions class="justify-end">
                                <v-btn
                                  color="red accent-4"
                                  style="color: white"
                                  @click="dialog.value = false"
                                  >Cerrar</v-btn
                                >
                              </v-card-actions>
                            </v-card>
                          </template>
                        </v-dialog>
                      </template>
                      <template v-slot:item.TotalFacturasNoGestionadasSinPagar="props">
                        <v-dialog transition="dialog-bottom-transition">
                          <template v-slot:activator="{ on, attrs }">
                            <p
                              @click="
                                temp = props.item.TotalFacturasNoGestionadasSinPagar
                              "
                              color="primary"
                              style="color: #2196f3; margin-bottom: 0"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ props.item.TotalFacturasNoGestionadasSinPagar }}
                            </p>
                          </template>
                          <template v-slot:default="dialog">
                            <v-card>
                              <v-toolbar color="primary" dark
                                >Datos Facturas Generadas No Gestionadas sin
                                pagar</v-toolbar
                              >
                              <v-card-text>
                                <simple-datatable
                                  style="margin-top: 1rem"
                                  :headers="headersInternos2"
                                  :datos="datosFacturasSinGestionarSinPagar"
                                >
                                </simple-datatable>
                              </v-card-text>
                              <v-card-actions class="justify-end">
                                <v-btn
                                  color="red accent-4"
                                  style="color: white"
                                  @click="dialog.value = false"
                                  >Cerrar</v-btn
                                >
                              </v-card-actions>
                            </v-card>
                          </template>
                        </v-dialog>
                      </template>
                      <template v-slot:item.TotalFacturasNoGestionadasPagadas="props">
                        <v-dialog transition="dialog-bottom-transition">
                          <template v-slot:activator="{ on, attrs }">
                            <p
                              @click="temp = props.item.TotalFacturasNoGestionadasPagadas"
                              color="primary"
                              style="color: #2196f3; margin-bottom: 0"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ props.item.TotalFacturasNoGestionadasPagadas }}
                            </p>
                          </template>
                          <template v-slot:default="dialog">
                            <v-card>
                              <v-toolbar color="primary" dark
                                >Datos Facturas Generadas No Gestionadas
                                Pagadas</v-toolbar
                              >
                              <v-card-text>
                                <simple-datatable
                                  style="margin-top: 1rem"
                                  :headers="headersInternos2"
                                  :datos="datosFacturasSinGestionarPagadas"
                                >
                                </simple-datatable>
                              </v-card-text>
                              <v-card-actions class="justify-end">
                                <v-btn
                                  color="red accent-4"
                                  style="color: white"
                                  @click="dialog.value = false"
                                  >Cerrar</v-btn
                                >
                              </v-card-actions>
                            </v-card>
                          </template>
                        </v-dialog>
                      </template>
                      <!-- <template v-slot:expanded-item="{ headers, item }">
                          <td :colspan="headers.length" style="padding: 0">
                            <v-card style="width: 100vw">
                              <v-card-text>
                                <v-btn
                                  @click="registrar(item)"
                                  height="100%"
                                  dark
                                  color="green darken-1"
                                >
                                  Registrar Entrega
                                </v-btn>
                              </v-card-text>
                            </v-card>
                          </td>
                        </template> -->
                    </v-data-table>
                  </template>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-app>
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
import axios from 'axios'
export default {
  data: () => ({
    fechaInicio: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    fechaFin: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    modal: false,
    modal2: false,
    headers: [
      {
        text: "Número Facturas Generadas",
        value: "TotalFacturasGeneradas",
      },
      {
        text: "Valor Total Facturas Generadas",
        value: "ValorFacturasGeneradas",
      },
      {
        text: "Número de facturas pendientes por pagar",
        value: "TotalFacturasPendientes",
      },
      {
        text: "Valor Facturas Pendiente por pagar ",
        value: "ValorFacturasPendientes",
      },
      {
        text: "Número de facturas con Recaudo",
        value: "TotalFacturasRecaudado",
      },
      {
        text: "Total Recaudado",
        value: "ValorFacturasRecaudado",
      },
      {
        text: "Número de facturas dobles",
        value: "TotalFacturasDoblementeGeneradas",
      },
      {
        text: "Valor Total facturas dobles",
        value: "ValorFacturasDoblementeGeneradas",
      },
      {
        text: "Número de facturas dobles pagadas",
        value: "TotalFacturasDoblementePagadas",
      },
      {
        text: "Valor Total facturas dobles Pagadas",
        value: "ValorFacturasDoblementePagadas",
      },
      {
        text: "Número Facturas Gestionadas",
        value: "TotalFacturasGestionadas",
      },
      {
        text: "Valor Total Facturas Gestionadas",
        value: "ValorFacturasGestionadas",
      },
      {
        text: "Número Facturas Gestionadas Sin Pagar",
        value: "TotalFacturasGestionadasSinPagar",
      },
      {
        text: "Valor Total Facturas Gestionadas Sin Pagar",
        value: "ValorFacturasGestionadasSinPagar",
      },
      {
        text: "Número Facturas Gestionadas Pagadas",
        value: "TotalFacturasGestionadasPagadas",
      },
      {
        text: "Valor Total Facturas Gestionadas Pagadas",
        value: "ValorFacturasGestionadasPagadas",
      },
      {
        text: "Número Facturas No Gestionadas",
        value: "TotalFacturasNoGestionadas",
      },
      {
        text: "Valor Total Facturas No Gestionadas",
        value: "ValorFacturasNoGestionadas",
      },
      {
        text: "Número Facturas No Gestionadas Sin Pagar",
        value: "TotalFacturasNoGestionadasSinPagar",
      },
      {
        text: "Valor Total Facturas No Gestionadas Sin Pagar",
        value: "ValorFacturasNoGestionadasSinPagar",
      },
      {
        text: "Número Facturas No Gestionadas Pagadas",
        value: "TotalFacturasNoGestionadasPagadas",
      },
      {
        text: "Valor Total Facturas No Gestionadas Pagadas",
        value: "ValorFacturasNoGestionadasPagadas",
      },
    ],
    datos: [],
    search: "",
    temp: null,
    datosDatatable: [],
    tipoContrato: -1,
    tiposContrato: [],
    ciclo: -1,
    ciclos: [],
    datosTotalGeneradas: [],
    datosPendientesPorPagar: [],
    datosFacturasConRecado: [],
    datosFacturasDobles: [],
    datosFacturasDoblesPagadas: [],
    headersInternos: [
      {
        text: "Número Factura",
        value: "NoFactura",
      },
      {
        text: "Tipo Contrato",
        value: "Tipo_Cont",
      },
      {
        text: "Nuevo Saldo",
        value: "Nvo_saldo",
      },
      {
        text: "Saldo Anterior ",
        value: "Saldo_Ant",
      },
      {
        text: "Valor Total",
        value: "Val_Tot",
      },
      {
        text: "Radicado",
        value: "grugru",
      },
      {
        text: "Nombre Factura",
        value: "Nombre_Fac",
      },
      {
        text: "Ciclo",
        value: "Ciclo",
      },
      {
        text: "Corte",
        value: "Corte",
      },
      {
        text: "Fecha Factura",
        value: "FechaFac",
      },
    ],
    headersInternos2: [
      {
        text: "ID Grupo",
        value: "ID_Grupo",
      },
      {
        text: "Nombre Factura",
        value: "Nombre_Fac",
      },
      {
        text: "Valor Total",
        value: "Val_Tot",
      },
      {
        text: "Celular Grupo",
        value: "gruCel",
      },
      {
        text: "Radicado",
        value: "grugru",
      },
      {
        text: "ID Factura",
        value: "id_Factura",
      },
      {
        text: "Número Factura",
        value: "NoFactura",
      },
      {
        text: "Número Factura 1",
        value: "NoFactura1",
      },
      {
        text: "Número Factura 2",
        value: "NoFactura2",
      },
      {
        text: "Número Factura 3",
        value: "NoFactura3",
      },
      {
        text: "Número Factura 4",
        value: "NoFactura4",
      },
      {
        text: "Fecha Factura",
        value: "FechaFac",
      },
      {
        text: "Fecha Llamada",
        value: "Fecha_llamada",
      },
      {
        text: "Hora Llamada",
        value: "Hora_llamada",
      },
      {
        text: "Id Llamada",
        value: "ID_llamadas",
      },
      {
        text: "Tipo De Actividad",
        value: "tipo_actividad",
      },
    ],
    datosFacturasGestionadas: [],
    datosFacturasGestionadasPagadas: [],
    datosFacturasGestionadasSinPagar: [],
    datosFacturasSinGestionar: [],
    datosFacturasSinGestionarPagadas: [],
    datosFacturasSinGestionarSinPagar: [],
  }),
  created() {
    this.$store.state.stateLoad = true;
    axios
      .get("/cobranza/tipocontratos", {
        headers: {
        },
      })
      .then((response) => {
        this.tiposContrato.push({
          clave: -1,
          valor: "TODOS",
        });
        response.data.forEach((element) => {
          this.tiposContrato.push({
            clave: element.clave,
            valor: element.valor,
          });
        });
      });
    axios
      .get("/cobranza/ciclos", {
        headers: {
        },
      })
      .then((response) => {
        this.ciclos.push({
          clave: -1,
          valor: "TODOS",
        });
        response.data.forEach((element) => {
          this.ciclos.push({
            clave: element.clave - 1,
            valor: element.valor,
          });
        });
        this.$store.state.stateLoad = false;
      });
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  methods: {
    buscar() {
      this.$store.state.stateLoad = true;
      axios
        .get("/cobranza/indicadoresDesempeno", {
          params: {
            fechaInicial: this.fechaInicio,
            fechaFinal: this.fechaFin,
            ciclo: this.ciclo,
            tipoContrato: this.tipoContrato,
          },
          headers: {
            },
        })
        .then((response) => {
          this.datos = [];
          this.datos.push({
            TotalFacturasGeneradas: response.data[0].total,
            ValorFacturasGeneradas:
              response.data[0].suma_total == null ? 0 : response.data[0].suma_total,
            TotalFacturasPendientes: response.data[1].total,
            ValorFacturasPendientes:
              response.data[1].suma_total == null ? 0 : response.data[1].suma_total,
            TotalFacturasRecaudado: response.data[2].total,
            ValorFacturasRecaudado:
              response.data[2].suma_total == null ? 0 : response.data[2].suma_total,
            TotalFacturasDoblementeGeneradas: response.data[3].total,
            ValorFacturasDoblementeGeneradas:
              response.data[3].suma_total == null ? 0 : response.data[3].suma_total,
            TotalFacturasDoblementePagadas: response.data[4].total,
            ValorFacturasDoblementePagadas:
              response.data[4].suma_total == null ? 0 : response.data[4].suma_total,
            TotalFacturasGestionadas: response.data[10].total,
            ValorFacturasGestionadas:
              response.data[10].suma_total == null ? 0 : response.data[10].suma_total,
            TotalFacturasGestionadasSinPagar: response.data[11].total,
            ValorFacturasGestionadasSinPagar:
              response.data[11].suma_total == null ? 0 : response.data[11].suma_total,
            TotalFacturasGestionadasPagadas: response.data[12].total,
            ValorFacturasGestionadasPagadas:
              response.data[12].suma_total == null ? 0 : response.data[12].suma_total,
            TotalFacturasNoGestionadas: response.data[13].total,
            ValorFacturasNoGestionadas:
              response.data[13].suma_total == null ? 0 : response.data[13].suma_total,
            TotalFacturasNoGestionadasSinPagar: response.data[14].total,
            ValorFacturasNoGestionadasSinPagar:
              response.data[14].suma_total == null ? 0 : response.data[14].suma_total,
            TotalFacturasNoGestionadasPagadas: response.data[15].total,
            ValorFacturasNoGestionadasPagadas:
              response.data[15].suma_total == null ? 0 : response.data[15].suma_total,
          });
          this.datosTotalGeneradas = [];
          response.data[5].forEach((element) => {
            this.datosTotalGeneradas.push(element);
          });
          this.datosPendientesPorPagar = [];
          response.data[6].forEach((element) => {
            this.datosPendientesPorPagar.push(element);
          });
          this.datosFacturasConRecado = [];
          response.data[7].forEach((element) => {
            this.datosFacturasConRecado.push(element);
          });
          this.datosFacturasDobles = [];
          response.data[8].forEach((element) => {
            this.datosFacturasDobles.push(element);
          });
          this.datosFacturasDoblesPagadas = [];
          response.data[9].forEach((element) => {
            this.datosFacturasDoblesPagadas.push(element);
          });
          this.datosFacturasGestionadas = [];
          response.data[16].forEach((element) => {
            this.datosFacturasGestionadas.push(element);
          });
          this.datosFacturasGestionadasSinPagar = [];
          response.data[17].forEach((element) => {
            this.datosFacturasGestionadasSinPagar.push(element);
          });
          this.datosFacturasGestionadasPagadas = [];
          response.data[18].forEach((element) => {
            this.datosFacturasGestionadasPagadas.push(element);
          });
          this.datosFacturasSinGestionar = [];
          response.data[19].forEach((element) => {
            this.datosFacturasSinGestionar.push(element);
          });
          this.datosFacturasSinGestionarPagadas = [];
          response.data[21].forEach((element) => {
            this.datosFacturasSinGestionarPagadas.push(element);
          });
          this.datosFacturasSinGestionarSinPagar = [];
          response.data[20].forEach((element) => {
            this.datosFacturasSinGestionarSinPagar.push(element);
          });
          this.$store.state.stateLoad = false;
        });
    },
    cambiarFecha() {
      if (this.fechaInicio > this.fechaFin) {
        this.fechaFin = new Date(this.fechaInicio).toISOString().substr(0, 10);
      }
    },
    excel() {
      axios({
        method: "post",
        url: "/excel",
        data: {
          datos: this.datos,
        },
        headers: {
        },

        responseType: "blob",
      }).then((response) => {
        // $("#boxLoading").removeClass("loading");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.xlsx");
        document.body.appendChild(link);
        link.click();
        // console.log(response);
      });
    },
  },
};
</script>
<style scoped>
h1 {
  font-size: larger !important;
}
.buscar {
  width: 200px;
  border-radius: 30px !important;
  background: #2196f3;
  margin-top: 10px;
  height: 2.2rem;
  color: white;
}
</style>
