<template>
  <v-container>
      <v-card>
          <v-toolbar dark color="grey darken-3" height="40">
              <v-spacer></v-spacer>
                <v-toolbar-title>CSC Traslado</v-toolbar-title>
              <v-spacer></v-spacer>
          </v-toolbar>
        
            <v-card-text>
   

             <v-text-field
                v-model="orden"
                hide-details
                single-line
                label="Consecutivo"
                type="number"
                />

            </v-card-text>

      </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'SignosSintomas',

    data: () => ({
        orden: 0,
    }),
  }
</script>
