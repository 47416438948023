//Los botones de cerrar y aceptar del modal se encuentran en la vista despacho en el componente Dialog.
<template>
  <v-container>
        <v-card>
            <v-toolbar dark height="40" color="grey darken-3">
              <v-spacer></v-spacer>
                <v-toolbar-title>PRQS</v-toolbar-title>
                <v-spacer></v-spacer>
          
            </v-toolbar>


        <v-card-text>
          
          <v-row>

 
               <v-col sm="6">
                  <v-select
                      :items="select_pqrs"
                      v-model="pqrs_seleccionado"
                      label="PQRS"
                     
                      outlined
                      hide-details
                    >

                  </v-select>
              </v-col>
               <v-col sm="6">
                          <v-textarea
                          label="Notas de PQRS"
                          auto-grow
                          outlined
                          rows="1"
                          row-height="7"
                          v-model="mensaje"
                        ></v-textarea>
              </v-col>

          </v-row>

        </v-card-text>
   
      </v-card>


  </v-container>
</template>


<script>
  export default {
    name: 'ModalPQR',

    data: () => ({
        select_pqrs: ['Paciente muy incoforme con el tiempo', 'Cambiar documento de identidad'],
        pqrs_seleccionado: '',
        mensaje: '',
      
    }),
    methods: {
  
    }
  }
</script>
