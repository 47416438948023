<template>
  <v-container>
      <v-card>
          <v-toolbar dark color="grey darken-3" height="40">
              <v-spacer></v-spacer>
                <v-toolbar-title>Forma de Pago</v-toolbar-title>
              <v-spacer></v-spacer>
          </v-toolbar>
        
            <v-card-text>
                <v-select
                  :items="items_select_forma_pago" 
                  v-model="forma_pago_seleccionada"
                  dense
                  outlined
                  hide-details   
                  label="Forma de pago"
                >
                    
                </v-select>
            </v-card-text>

      </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'ModalFPago',

    data: () => ({
        items_select_forma_pago: ['_INDETERMINADO_','BENEFICIARIO Normal','PARTICULAR efectivo', 'PARTICULAR credito',
            'TRANSEUNTE', 'LLAMADA CANCELADA',' EQUIVOCACIÓN'

        ],
        forma_pago_seleccionada: '',
    }),
  }
</script>
