var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{style:({ background: _vm.$vuetify.theme.themes.light.background })},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',[_c('v-app',{style:({ background: _vm.$vuetify.theme.themes.dark.background })},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"pt-5 pb-5",staticStyle:{"width":"100%"}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-card',{staticStyle:{"padding":"1rem"}},[_c('v-row',[_c('v-col',{staticStyle:{"padding-top":"0.5rem"},attrs:{"cols":"12","sm":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.informes,"dense":"","background-color":"white","filled":"","height":"10","label":"Seleccionar Informe","item-text":"clave","item-value":"valor"},model:{value:(_vm.informe),callback:function ($$v) {_vm.informe=$$v},expression:"informe"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.fechaInicio,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.fechaInicio=$event},"update:return-value":function($event){_vm.fechaInicio=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha De Inicio","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fechaInicio),callback:function ($$v) {_vm.fechaInicio=$$v},expression:"fechaInicio"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"locale":"es","scrollable":""},on:{"change":_vm.cambiarFecha},model:{value:(_vm.fechaInicio),callback:function ($$v) {_vm.fechaInicio=$$v},expression:"fechaInicio"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.fechaInicio)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-dialog',{ref:"dialog2",attrs:{"return-value":_vm.fechaFin,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.fechaFin=$event},"update:return-value":function($event){_vm.fechaFin=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha De Fin","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fechaFin),callback:function ($$v) {_vm.fechaFin=$$v},expression:"fechaFin"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal2),callback:function ($$v) {_vm.modal2=$$v},expression:"modal2"}},[_c('v-date-picker',{attrs:{"locale":"es","scrollable":""},model:{value:(_vm.fechaFin),callback:function ($$v) {_vm.fechaFin=$$v},expression:"fechaFin"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal2 = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog2.save(_vm.fechaFin)}}},[_vm._v(" OK ")])],1)],1)],1),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{attrs:{"disabled":!_vm.informe,"rounded":"","color":"primary","dark":""},on:{"click":_vm.exportar}},[_vm._v(" Exportar ")]),(_vm.informe == 1)?_c('v-dialog',{attrs:{"width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"rounded":"","color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Cambiar Corte ")])]}}],null,false,4105183471),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Cambiar el corte")])]),_c('v-card-text',[_c('v-text-field',{attrs:{"hide-details":"","single-line":"","type":"number"},model:{value:(_vm.corte),callback:function ($$v) {_vm.corte=$$v},expression:"corte"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){(_vm.dialog = false), (_vm.corte = null)}}},[_vm._v(" Cerrar ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.cambiarCorte(_vm.corte), (_vm.dialog = false), (_vm.corte = null)}}},[_vm._v(" Guardar ")])],1)],1)],1):_vm._e()],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }