<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <v-layout row>
      <v-flex>
        <v-app :style="{ background: $vuetify.theme.themes.dark.background }" class="">
          <v-container fluid>
            <v-row class="pt-5 pb-5" style="width: 100%">
              <v-col cols="12" sm="12">
                <v-card style="padding: 1rem">
                  <v-col cols="12" sm="12">
                    <h1>Seleccione el ciclo a consultar</h1>
                    <h1>El Ciclo Actual Es: {{ cicloActual }}</h1>
                    <v-autocomplete
                      v-model="ciclo"
                      :items="ciclos"
                      dense
                      filled
                      label="Filled"
                      item-text="valor"
                      item-value="clave"
                      @change="buscarData"
                    ></v-autocomplete>
                  </v-col>
                  <div class="d-flex justify-center">
                    <v-btn
                      @click="descargar(), (buscando = true)"
                      rounded
                      color="primary"
                      :disabled="ciclo == null"
                    >
                      Descargar Pdf
                    </v-btn>
                    <v-btn
                      @click="excel(), (buscando = true)"
                      rounded
                      color="primary"
                      :disabled="datos.length == 0"
                    >
                      Descargar Excel
                    </v-btn>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-app>
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    ciclos: [],
    ciclo: null,
    buscando: false,
    cicloActual: null,
    datos: [],
  }),
  created() {
    this.$store.state.stateLoad = true;
    axios
      .get("/servicio_medico/pdfCicloActivo", {
        headers: {
        },
      })
      .then((response) => {
        console.log(response.data);
        response.data.forEach((element) => {
          this.ciclos.push({
            clave: element.clave,
            valor: element.valor,
          });
        });
      });
    axios
      .get("/servicio_medico/cicloActual", {
        headers: {
        },
      })
      .then((response) => {
        this.cicloActual = response.data[0].cliclo_activo;
        this.$store.state.stateLoad = false;
      });
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  methods: {
    descargar() {
      /* axios
        .get(this.$BASEURL + "/servicio_medico/descargarPdf", {
          params: {
            ciclo: this.ciclo,
          },
        })
        .then((response) => {}); */
      this.$store.state.stateLoad = true;
      const method = "GET";

      const url = "/servicio_medico/descargarPdf";
      axios

        .request({
          url,

          method,

          responseType: "blob",
          params: {
            ciclo: this.ciclo,
          }, //important
          headers: {
            },
        })
        .then(({ data }) => {
          this.$store.state.stateLoad = false;
          const downloadUrl = window.URL.createObjectURL(new Blob([data]));

          const link = document.createElement("a");

          link.href = downloadUrl;

          link.setAttribute("download", "file.pdf"); //any other extension

          document.body.appendChild(link);

          link.click();

          link.remove();
        });
    },
    buscarData(e) {
      this.datos = [];
      this.$store.state.stateLoad = true;
      axios
        .get("/servicio_medico/dataExcelPdf", {
          params: {
            ciclo: e,
          },
          headers: {
            },
        })
        .then((response) => {
          this.datos = response.data;
          this.$store.state.stateLoad = false;
        });
    },
    excel() {
      this.$store.state.stateLoad = true;
      axios({
        method: "post",
        url:  "/excel",
        data: {
          datos: this.datos,
        },
        headers: {
          },
        responseType: "blob",
      }).then((response) => {
        this.$store.state.stateLoad = false;
        // $("#boxLoading").removeClass("loading");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.xlsx");
        document.body.appendChild(link);
        link.click();
        // console.log(response);
      });
    },
  },
};
</script>
<style scoped>
h1 {
  font-size: larger !important;
}
.buscar {
  width: 200px;
  border-radius: 30px !important;
  background: #2196f3;
  margin-top: 10px;
  height: 2.2rem;
  color: white;
}
</style>
