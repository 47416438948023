//Los botones de cerrar y aceptar del modal se encuentran en la vista despacho en el componente Dialog.
<template>
  <v-container>
        <v-card>
            <v-toolbar dark height="40" color="grey darken-3">
              <v-spacer></v-spacer>
                <v-toolbar-title>CEL NOTIFICACIONES</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>


        <v-card-text>
            <v-col cols="12">

            </v-col>
            <v-label>Mensajes: ({{cel}})</v-label>
            <v-divider></v-divider>
       
                <v-col>
                    <v-select
                        v-model="descripcion"
                        :items="items_select_descripcion"
                        hide-details
                        label="Descripción"
                        >

                    </v-select>
                </v-col>
                <v-col class="">
                    <v-textarea
                    autocomplete="email"
                    label="Mensaje"
                    height="60"
                    dense
                    filled
                    disabled
                    ></v-textarea>
                </v-col>
       

        </v-card-text>
   
      </v-card>


  </v-container>
</template>


<script>
  export default {
    name: 'CelNotificaciones',

    data: () => ({
        cel: '3122312312',
        items_select_descripcion: ['as', 'sdf'],
        descripcion:'',
        mensaje:'',
    }),
    methods: {
  
    }
  }
</script>
