<template>
  <nav>
    <link
      href="https://cdn.jsdelivr.net/npm/@mdi/font@5.x/css/materialdesignicons.min.css"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css?family=Material+Icons"
      rel="stylesheet"
    />
    <link
      href="https://cdn.jsdelivr.net/npm/font-awesome@4.x/css/font-awesome.min.css"
      rel="stylesheet"
    />
    <link
      href="https://use.fontawesome.com/releases/v5.0.13/css/all.css"
      rel="stylesheet"
    />
    <v-app-bar class="teal">
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        style="color: white"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      {{ user }}
    </v-app-bar>
    <v-navigation-drawer
      style="position: fixed"
      :expand-on-hover="expand_on_hover"
      :permanent="!temporary"
      v-model="drawer"
      :absolute="true"
      :temporary="temporary"
      dark
      app
      class="teal"
    >
      <v-list nav dense>
        <v-list-item class="mb-5">
          <v-list-item-content>
            <v-icon @click="fix" class="mb-2" large> fas fa-user-md </v-icon>
            <v-list-item-subtitle align="center"> VITALSOFT </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list flat>
        <v-list-item v-if="categorias[1]">
          <v-list-item-content>
            <v-list-group :value="false" color="blue-grey lighten-3">
              <template v-slot:activator>
                <v-list-item-title>Dashboard</v-list-item-title>
              </template>

              <v-list-item-content>
                <v-list-item
                  route
                  v-if="credentials['Dashboard']"
                  r
                  :to="{ name: 'inicio' }"
                >
                  <v-list-item-content>
                    <v-icon class="mb-2"> fas fa-home </v-icon>
                    <v-list-item-subtitle align="center"> Inicio </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <!--<v-list-item router to="/about">
                  <v-list-item-content>
                    <v-icon class="mb-2"> fas fa-user </v-icon>
                    <v-list-item-subtitle align="center"> Usuario </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>-->
                <v-list-item
                  v-if="credentials['Agenda Personal']"
                  router
                  :to="{ name: 'calendar' }"
                >
                  <v-list-item-content>
                    <v-icon class="mb-2"> fas fa-calendar </v-icon>
                    <v-list-item-subtitle align="center">
                      Calendario
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item router :to="{ name: 'agregar' }" v-if="false">
                  <v-list-item-content>
                    <v-icon class="mb-2"> fas fa-user-plus </v-icon>
                    <v-list-item-subtitle align="center">
                      Agregar Usuario
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-content>
            </v-list-group>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="categorias[2]">
          <v-list-item-content>
            <v-list-group :value="false" color="blue-grey lighten-3">
              <template v-slot:activator>
                <v-list-item-title>Facturación</v-list-item-title>
              </template>
              <v-list-item
                router
                v-if="credentials['Revision Facturas']"
                :to="{ name: 'revisionfacturas' }"
                style="padding: 0"
              >
                <v-list-item-content>
                  <v-icon class="mb-2"> fas fa-book </v-icon>
                  <v-list-item-subtitle align="center">
                    Revisión Facturas
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                router
                v-if="credentials['Revision Anexos']"
                :to="{ name: 'revisionanexos' }"
                style="padding: 0"
              >
                <v-list-item-content>
                  <v-icon class="mb-2"> fas fa-book </v-icon>
                  <v-list-item-subtitle align="center">
                    Revisión Anexos
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="categorias[3]">
          <v-list-item-content>
            <v-list-group :value="false" color="blue-grey lighten-3">
              <template v-slot:activator>
                <v-list-item-title>Consultas</v-list-item-title>
              </template>
              <v-list-item
                router
                v-if="credentials['Tiempo Promedio']"
                :to="{ name: 'tiempopromedioconsulta' }"
                style="padding: 0"
              >
                <v-list-item-content>
                  <v-icon class="mb-2"> fas fa-book </v-icon>
                  <v-list-item-subtitle align="center">
                    Tiempo Promedio Consulta
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="categorias[4]">
          <v-list-item-content>
            <v-list-group :value="false" color="blue-grey lighten-3">
              <template v-slot:activator>
                <v-list-item-title>Informes</v-list-item-title>
              </template>
              <v-list-item
                router
                v-if="credentials['Informe Dian']"
                :to="{ name: 'informedian' }"
                style="padding: 0"
              >
                <v-list-item-content>
                  <v-icon class="mb-2"> fas fa-book </v-icon>
                  <v-list-item-subtitle align="center">
                    Informe Dian
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'despacho' }">
          <v-list-item-content>
            <v-icon class="mb-2"> fas fa-book </v-icon>
            <v-list-item-subtitle align="center"> Despacho </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="categorias[5]">
          <v-list-item-content>
            <v-list-group :value="false" color="blue-grey lighten-3">
              <template v-slot:activator>
                <v-list-item-title>Cobranza</v-list-item-title>
              </template>
              <v-list-item
                router
                v-if="credentials['Indicadores Desempeno']"
                :to="{ name: 'indicadoresdesempeno' }"
                style="padding: 0"
              >
                <v-list-item-content>
                  <v-icon class="mb-2"> fas fa-book </v-icon>
                  <v-list-item-subtitle align="center">
                    Indicadores De Desempeño
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-group
                v-if="categorias[6]"
                no-action
                sub-group
                color="blue-grey lighten-3"
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>Cartera Vencida</v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item
                  router
                  v-if="credentials['Cartera Vencida Gerencia']"
                  :to="{ name: 'gestionarcarteravencida' }"
                  style="padding: 0"
                >
                  <v-list-item-content>
                    <v-icon class="mb-2"> fas fa-book </v-icon>
                    <v-list-item-subtitle align="center">
                      Gestionar Cartera Vencida Gerencia
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  router
                  v-if="credentials['Cartera Vencida']"
                  :to="{ name: 'gestionarcarteravencida2' }"
                  style="padding: 0"
                >
                  <v-list-item-content>
                    <v-icon class="mb-2"> fas fa-book </v-icon>
                    <v-list-item-subtitle align="center">
                      Gestionar Cartera Vencida
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-list-group>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list style="vertical-align: baseline" class="ml-3" flat>
        <v-list-item @click="signOut">
          <v-list-item-action>
            <v-icon right> fas fa-sign-out-alt </v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import axios from "axios";
import "material-design-icons-iconfont/dist/material-design-icons.css";
export default {
  data: () => ({
    drawer: false,
    calendarComponent: false,
    groupVetService: false,
    dewormingComponent: false,
    dewormingRegisterComponent: false,
    dewormingHistoricComponent: false,
    reportComponent: false,
    collectorsComponent: false,
    CicloActivo: false,
    moduloCicloActivo: false,
    expand_on_hover: false,

    temporary: true,
    user: null,
    credentials: null,
    facturacion: false,
    consultas: false,
    informes: false,
    cobranza: false,
    contabilidad: false,
    categorias: null,
  }),
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  methods: {
    signOut(event) {
      event.preventDefault();

      axios
        .post(
          "/logout",
          {},
          {
            headers: {},
          }
        )
        .then((response) => {
          localStorage.removeItem("token");
          this.$router.push({
            name: "Login",
          });
        });
    },
    fix() {
      this.temporary = !this.temporary;
    },
  },
  created() {
    this.user = this.$store.state.user.nombre1;
    this.credentials = this.$store.state.credentials;
    this.categorias = this.$store.state.categorias;
  },
  beforeMount() {
    /* userCredentials.forEach(element => {
      if(element.pivot.permission_id == 1){
        this.calendarComponent = true;
      }
    }); */
  },
};
</script>
<style scoped>
.v-list-item {
  padding: 5px !important;
}
</style>
