<template class="bg">
  <div id="container" class="container" ref="container">
    <div class="content">
      <h2>404</h2>
      <h4>Opps! Pagina no encontrada</h4>
      <p class="parrafo">
        La pagina que estas intentando ver no existe. Asegurate de haber escrito bien la
        dirección o vuelve a la pagina principal
      </p>
      <a @click.prevent="goHome()" class="boton" href="">Ir al Home</a>
    </div>
    <div id="mydiv" ref="myid"></div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Page404",

  methods: {
    goHome() {
      this.$router.push("/");
    },
  },
  mounted() {
    const container = document.getElementById("container");
    window.onmousemove = function (e) {
      let x = e.clientX,
        y = e.clientY;
      container.style.backgroundPositionX = x + "px";
      container.style.backgroundPositionY = y + "px";
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900");
* {
  margin: 0px;
  padding: 0px;
  font-family: "Poppins", sans-serif;
}
body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
body::-webkit-scrollbar {
  display: none;
}

.bg {
  background: #1ab627;
}

.container {
  /* position: absolute;
  top: 10%;
  left: 10%;
  right: 10%;
  bottom: 10%; */
  /* border-radius: 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../assets/p404.png"), #8867c2;

  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.5);
  height: 100vh;
}

.container {
  max-width: 100% !important;
  text-align: center;
}

.logo404 {
  /* width: 340px; */
  width: 35vw;
}

.container h2 {
  font-size: 18vw;
  color: #fff;
  line-height: 1em;
  text-shadow: 8px 8px 8px rgba(83, 29, 100, 0.527);
}

.container h4 {
  position: relative;
  font-size: 1.5em;
  margin-bottom: 20px;
  color: rgb(255, 230, 7);
  text-shadow: 1px 1px 10px 10px rgba(15, 0, 0, 0.562);
}

#container .content {
  max-width: 600px;
  text-align: center;
}

.parrafo {
  color: rgb(255, 255, 255);
  font-size: 1.2em;
}

.boton {
  display: inline-block;
  background-color: #19c4b3;
  padding: 10px 25px;
  color: #fff;
  text-decoration: none;
  margin-top: 25px;
  border-radius: 25px;
  border-bottom: 4px solid #04a393;
  box-shadow: 1px 1px 3px 0px #474747;
}

.boton:hover {
  display: inline-block;
  background-color: #1cccba;
  border-bottom: 4px solid #04a393;
  box-shadow: 0px 0px 143px 0px #ffffff;
  font-size: 16.5px;
}

/* #nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
