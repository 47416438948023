<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <v-layout row>
      <v-flex>
        <v-app :style="{ background: $vuetify.theme.themes.dark.background }" class="">
          <v-container fluid>
            <v-row class="pt-5 pb-5" style="width: 100%">
              <v-col cols="12" sm="12">
                <v-card style="padding: 1rem">
                  <div class="d-flex justify-center" style="margin-bottom: 1rem">
                    <v-text-field
                      label="Consecutivo"
                      v-model="consecutivo"
                    ></v-text-field>
                  </div>
                  <v-row>
                    <div class="d-flex justify-center">
                      <v-btn @click="descargar()" rounded color="primary" dark>
                        Buscar
                      </v-btn>
                    </div>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-app>
      </v-flex>
    </v-layout>
    <v-snackbar v-model="snackbar">
      Aun no completa su historia medica, pdf no habilitado

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false"> Cerrar </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    consecutivo: null,
    modal: false,
    modal2: false,
    snackbar: false,
  }),
  created() {},
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  methods: {
    descargar() {
      this.$store.state.stateLoad = true;

      axios({
        method: "post",
        url: "crud.php",
        baseURL: "https://hclinica.vitalmascotas.com.co/php/",
        data: {
          opcion: 36,
          consecutivo: this.consecutivo,
        },
      }).then((response) => {
        if (response.data == 2) {
          axios({
            url: "crud.php",
            baseURL: "https://hclinica.vitalmascotas.com.co/php/",

            method: "POST",
            data: {
              consecutivo: this.consecutivo,
              opcion: 35,
              documento: 1,
            },

            responseType: "blob",
          }).then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));

            var fileLink = document.createElement("a");

            fileLink.href = fileURL;

            fileLink.setAttribute("download", "historia.pdf");

            document.body.appendChild(fileLink);

            this.$store.state.stateLoad = false;
            fileLink.click();
          });
        } else {
          
          this.$store.state.stateLoad = false;
          this.snackbar = true;
        }
      });
    },
  },
};
</script>
<style scoped>
h1 {
  font-size: larger !important;
}
.buscar {
  width: 200px;
  border-radius: 30px !important;
  background: #2196f3;
  margin-top: 10px;
  height: 2.2rem;
  color: white;
}
</style>
