//Los botones de cerrar y aceptar del modal se encuentran en la vista despacho en el componente Dialog.
<template>
  <v-container>
        <v-card>
            <v-toolbar dark height="40" color="grey darken-3">
              <v-spacer></v-spacer>
                <v-toolbar-title>Dirección</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>

                <v-col cols="12">
                    <v-text-field v-model="direccion_antigua" dense hide-details  outlined label="Dirección Registrada Antigua"></v-text-field>
                </v-col>

                <v-row class="pa-0 ma-0">
                   <v-col cols="12" sm="6">
                        <v-row class="pa-0 ma-0">
                            <v-col cols="12"  sm="6" class="pa-0 ma-0">
                                 <v-select
                                    :items="items_select_bloque_interior"
                                    label="Bloque o Interior"
                                    outlined
                                    dense
                                    hide-details
                                    v-model="bloque_interior"
                                  ></v-select>          
                            </v-col>
                            <v-col sm="6" class="pa-0 ma-0">
                                <v-text-field v-model="n_bloque_interior" dense hide-details  outlined ></v-text-field>
                            </v-col>
                        </v-row>

                   </v-col >
                   <v-col cols="12" sm="6">
                        <v-select
                                    :items="items_select_ciudad"
                                    label="Ciudad"
                                    outlined
                                    dense
                                    hide-details
                                    v-model="ciudad"
                                  ></v-select>       
                   </v-col>
                </v-row>

                  <v-row class="pa-0 ma-0">
                   <v-col cols="12" sm="6">
                        <v-row class="pa-0 ma-0">
                            <v-col cols="12" sm="6" class="pa-0 ma-0">
                                 <v-select
                                    :items="items_select_tipo_inmueble"
                                    label="Tipo de inmueble"
                                    outlined
                                    dense
                                    hide-details
                                    v-model="tipo_inmueble"
                                  ></v-select>          
                            </v-col>
                            <v-col sm="6" class="pa-0 ma-0">
                                <v-text-field v-model="n_tipo_inmueble" dense hide-details  outlined ></v-text-field>
                            </v-col>
                        </v-row>

                   </v-col >
                   <v-col cols="12" sm="6">
                        <v-select
                                    :items="items_select_barrio"
                                    label="Barrio"
                                    outlined
                                    dense
                                    hide-details
                                    v-model="barrio"
                                  ></v-select>       
                   </v-col>
                </v-row>


                  <v-row class="pa-0 ma-0">
                   <v-col cols="12" sm="6">
                        <v-row class="pa-0 ma-0">
                            <v-col sm="12" class="pa-0 ma-0">
                                 <v-select
                                    :items="items_select_tipo_via"
                                    label="Tipo de vía"
                                    outlined
                                    dense
                                    hide-details
                                    v-model="tipo_via"
                                  ></v-select>          
                            </v-col>
                      
                        </v-row>

                   </v-col >
                   <v-col cols="12" sm="6">
                      <v-switch
                            v-model="switch_edificio_conjunto"
                            label="Edificio/Conjunto"
                          ></v-switch>
                         
                   </v-col>
                </v-row>

                 <v-row class="pa-0 ma-0">
                   <v-col cols="12" sm="6">

                        <v-label>Dirección</v-label>
                        
                        <v-row class="pa-0 ma-0">
                           
                            <v-col sm="4"  class="pa-0 ma-0">
                                  <v-text-field v-model="direccion_cra" dense hide-details  outlined label="Cra/Cll"></v-text-field>      
                            </v-col>
                           
                            <v-col sm="4" class="pa-0 ma-0">
                                  <v-text-field v-model="direccion_numero" dense hide-details  outlined label="#"></v-text-field>      
                            </v-col>
                            
                            <v-col sm="4" class="pa-0 ma-0">
                                  <v-text-field  v-model="direccion_n" dense hide-details  outlined label="N°"></v-text-field>      
                            </v-col>
           
                        </v-row>
                          <v-row class="ml-1">
                          <v-switch
                            v-model="switch_parqueadero"
                            label="Parqueadero"
                          ></v-switch>
                          </v-row>
                   </v-col >
                   <v-col cols="12" sm="6" class="mt-5">
                       <v-text-field v-model="comuna" dense hide-details  outlined label="Comuna"></v-text-field>
                   </v-col>
             
                </v-row>

                <v-row class="pa-0 ma-0">
                  <v-col cols="12">
                          <v-textarea
                          label="Observaciones"
                          auto-grow
                          outlined
                          rows="1"
                          row-height="15"
                          v-model="observaciones"
                        ></v-textarea>
                  </v-col>
                </v-row>    
            </v-row>
                   <v-btn
                        small
                        color="grey darken-2"
                        dark
                        @click="asignarCoordenadas()"
                      >
                        Asignar Coordenadas
                      </v-btn>
                <v-btn
                        small
                        color="amber"
                        @click="sinValidar()"
                      >
                        Sin Validar
                      </v-btn>      
          </v-container>
         
        </v-card-text>
   
      </v-card>


  </v-container>
</template>

<script>
  export default {
    name: 'Direccion',

    data: () => ({
       direccion_antigua: ' ',
       bloque_interior: ' ',
       items_select_bloque_interior: [''],
       n_bloque_interior: '',
       tipo_inmueble: '',
       items_select_tipo_inmueble: [''],
       items_select_barrio: [''],
       n_tipo_inmueble: '',
       tipo_via: '',
       items_select_tipo_via: ['Autopista','Calle','Sector','Carrera','Circular','Transversal', 'Diagonal', 'Kilometro','Zona', 'Otros'],
       direccion_cra: '',
       direccion_numero: '',
       direccion_n: '',
       switch_parqueadero: '',
       observaciones: '',
       ciudad: '',
       comuna: '',
       barrio: '',
       switch_edificio_conjunto: '',
       items_select_ciudad: ['','']
    }),
    methods: {
      asignarCoordenadas(){
        console.log('asignando coordenadas');
      },
      sinValidar(){ 
        console.log('Sin validar');
      }
    }
  }
</script>
