//Los botones de cerrar y aceptar del modal se encuentran en la vista despacho en el componente Dialog.
<template>
  <v-container>
        <v-card>
            <v-toolbar dark height="40" color="grey darken-3">
              <v-spacer></v-spacer>
                <v-toolbar-title>Registro Llamada</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn  small color="green"><v-icon > mdi-plus</v-icon></v-btn>
            </v-toolbar>


        <v-card-text>
          
          <v-row>

              <v-col sm="3" class="mt-4">
                 <v-label >Registro llamada N° 1</v-label>
              </v-col>
               <v-col sm="3">
                  <v-select
                      :items="select_registro_llamada"
                      v-model="rllamada_seleccionado"
                      label="Rllamada de"
                     
                      outlined
                      hide-details
                    >

                  </v-select>
              </v-col>
               <v-col sm="6">
                          <v-textarea
                          label="Notas de operador"
                          auto-grow
                          outlined
                          rows="1"
                          row-height="7"
                          v-model="mensaje"
                        ></v-textarea>
              </v-col>

          </v-row>

        </v-card-text>
   
      </v-card>


  </v-container>
</template>


<script>
  export default {
    name: 'ModalRegistroLlamada',

    data: () => ({
        select_registro_llamada: ['Cliente', 'Operador'],
        rllamada_seleccionado: '',
        mensaje: '',
      
    }),
    methods: {
  
    }
  }
</script>
