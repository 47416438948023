<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="datos"
      item-key="nombre"
      class="elevation-1"
      :search="search"
      :footer-props="{
        showFirstLastPage: true,

        'items-per-page-text': 'Usuarios Por Pagina',
      }"
    >
      <template v-slot:top>
        <v-text-field v-model="search" label="Filtrar" class="mx-4"></v-text-field>
      </template>
      <template v-slot:item="row">
        <tr>
          <td>{{ row.item.id }}</td>
          <td>{{ row.item.nombre }}</td>
          <td>{{ row.item.correo }}</td>
          <td>
            <v-autocomplete
              v-model="row.item.value"
              :items="row.item.items"
              chips
              small-chips
              multiple
              disabled
            ></v-autocomplete>
          </td>
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="yellow accent-3"
              style="color: black !important"
              @click="onButtonClick(row)"
            >
              <v-icon dark>mdi-pencil</v-icon>
            </v-btn>
          </td>
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="red darken-2"
              @click="onButtonClick(row)"
            >
              <v-icon dark>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
// import axios from "axios";
export default {
  data: () => ({
    search: "",
    

   
  }),
  props: ['headers','datos'],
  
  methods: {
    onButtonClick(item) {
      // console.log(item);
      // item.item = null;
      // console.log(item)
    //   console.log(item.item.id);
    //   console.log(this.datos);
    //   for (let indice = 0; indice < this.datos.length; indice++) {
    //     if(this.datos[indice].id == item.item.id){
    //       this.datos.splice(indice, 1);
    //     }
        
    //   }
        // console.log(item.item);
        // this.$store.state.userModify = item.item.id;
        // this.$store.state.userModifyInfo = item.item;
        
        localStorage.setItem('userModify',item.item.id);
        localStorage.setItem('userModifyInfo', JSON.stringify(item.item));
        localStorage.setItem('userPermissions', JSON.stringify(item.item.value));
        this.$router.push({ name: 'editar'})
    
    },
    prueba() {
      console.log(this.peopleSelected);
    },
    filterOnlyCapsText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
  },
};
</script>
