<template>
  <v-data-table
    :headers="headers"
    :items="datos"
    item-key="ID_Desparasitacion"
    class="elevation-1"
    :search="search"
    :sort-by="'Nombre_Fac'"
    ref="datatable"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>
          <v-btn @click="excel" height="100%" dark color="green darken-1">
            <v-icon class="mb-2" large> fas fa-file-excel </v-icon>
          </v-btn>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <v-switch
                            v-model="singleExpand"
                            label="Single expand"
                            class="mt-2"
                          ></v-switch> -->
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
      </v-toolbar>
    </template>
    <template v-slot:item.cuantosModal="props">
      <v-dialog transition="dialog-bottom-transition">
        <template v-slot:activator="{ on, attrs }">
          <p
            @click="dataPorGruGru(props.item.grugru, props.item.NoFactura)"
            color="primary"
            style="color: #2196f3; margin-bottom: 0"
            v-bind="attrs"
            v-on="on"
          >
            {{ props.item.cuantosModal }}
          </p>
        </template>
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar color="primary" dark>Datos Facturas Generadas</v-toolbar>
            <v-card-text>
              <h1 style="margin: 1rem; text-align: initial">Pagos Hechos</h1>
              <simple-datatable
                style="margin-top: 1rem"
                :headers="headers2"
                :datos="datos2"
              >
              </simple-datatable>
              <h1 style="margin: 1rem; text-align: initial">Notas Contables</h1>
              <simple-datatable
                style="margin-top: 1rem"
                :headers="headers3"
                :datos="datos3"
              >
              </simple-datatable>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                color="red accent-4"
                style="color: white"
                @click="dialog.value = false"
                >Cerrar</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </template>
    <template v-slot:item.Nvo_saldo="props">
      <p>{{ props.item.Nvo_saldo | numbers }}</p>
    </template>
    <template v-slot:item.Saldo_Ant="props">
      <p>{{ props.item.Saldo_Ant | numbers }}</p>
    </template>
    <template v-slot:item.Val_Tot="props">
      <p>{{ props.item.Val_Tot | numbers }}</p>
    </template>
    <template v-slot:item.RCCABONOS="props">
      <p>{{ props.item.RCCABONOS | numbers }}</p>
    </template>
    <template v-slot:item.Val_Unit="props">
      <p>{{ props.item.Val_Unit | numbers }}</p>
    </template>
  </v-data-table>
</template>

<script>
import axios from 'axios'
export default {
  props: ["headers", "datos"],
  data: () => ({
    search: "",
    datos2: [],
    datos3: [],
    headers2: [
      {
        text: "Número Recibo",
        value: "NoRecibo",
      },
      {
        text: "Abono",
        value: "RCCABONOS",
      },
      {
        text: "Fecha Recibo",
        value: "RCCDATE"
      },
      {
        text: "Valor Total ",
        value: "Val_Tot",
      },
      {
        text: "Número Factura",
        value: "NoFactura",
      },
      {
        text: "Fecha Recibo",
        value: "FechaRec",
      },
      {
        text: "Fecha Factura",
        value: "FechaFac",
      },
      {
        text: "Valor Unitario",
        value: "Val_Unit",
      },
      {
        text: "Saldo Anterior",
        value: "Saldo_Ant",
      },
      {
        text: "Nuevo Saldo",
        value: "Nvo_saldo",
      },
    ],
    headers3: [
      {
        text: "Número Nota",
        value: "NOTNUM",
      },
      {
        text: "Fecha Nota",
        value: "NOTFEC",
      },
      {
        text: "Valor Nota",
        value: "NOTVAL",
      },
      {
        text: "Motivo ",
        value: "Motivo",
      },
      {
        text: "Detalle Nota ",
        value: "NOTDET",
      },
    ],
  }),
  created() {},
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  methods: {
    excel() {
      this.$store.state.stateLoad = true;
      axios({
        method: "post",
        url: "/excel",
        data: {
          datos: this.datos,
        },
        headers: {
        },
        responseType: "blob",
      }).then((response) => {
        this.$store.state.stateLoad = false;
        // $("#boxLoading").removeClass("loading");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.xlsx");
        document.body.appendChild(link);
        link.click();
        // console.log(response);
      });
    },
    async dataPorGruGru(GruGru, Factura) {
      this.data2 = [];
      this.$store.state.stateLoad = true;
      await axios
        .get("/cobranza/carteravencida/dataPagados", {
          params: {
            grugru: GruGru,
            factura: Factura,
          },
        headers: {
        },
        })
        .then((response) => {
          this.$store.state.stateLoad = false;
          console.log(response.data);
          this.datos2 = response.data[0];
          this.datos3 = response.data[1];
        });
    },
  },
};
</script>

<style></style>
